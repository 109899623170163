import {HOUR_OPTIONS, MINUTE_OPTIONS, MONTH_OPTIONS, YEAR_OPTIONS} from 'consts'
import {unavailabilityHooks} from 'hooks/unavailabilities'
import {ReactComponent as LockClock} from 'icons/LockClock.svg'
import moment from 'moment'
import {useState} from 'react'
import {Form} from 'react-final-form'
import {Components, Fields} from 'ui'
import {getDayOptions} from 'utils/getDayOptions'

type Props = {
  onAdd: () => void
}

export const AddUnavailability = ({onAdd}: Props) => {
  const now = moment()
  const inOneHour = moment().add(1, 'hour')
  const [shown, setShown] = useState(false)

  const unavailabilityCreateMutation = unavailabilityHooks.useCreateMutation()

  const onSubmit = (values: any) => {
    const startDateTime = moment
      .utc(
        moment({
          year: values.fromYear,
          month: values.fromMonth,
          day: values.fromDay,
          hour: values.fromHour,
          minute: values.fromMinute,
        })
      )
      .format()

    const endDateTime = moment
      .utc(
        moment({
          year: values.toYear,
          month: values.toMonth,
          day: values.toDay,
          hour: values.toHour,
          minute: values.toMinute,
        })
      )
      .format()

    unavailabilityCreateMutation.mutate(
      {
        startedAt: startDateTime,
        endedAt: endDateTime,
        comment: values.comment,
      },
      {
        onSuccess() {
          setShown(false)
          onAdd()
        },
      }
    )
  }

  return (
    <>
      <button
        className="fixed right-[20px] bottom-[20px] flex flex-nowrap gap-2 items-center justify-center bg-green-500 w-12 h-12 z-[1] rounded-full"
        onClick={() => setShown(true)}
      >
        <LockClock className="w-6 h-6 text-white" />
      </button>
      {shown && (
        <Components.Modal fullscreen title="New unavailability" onClose={() => setShown(false)}>
          <Form
            initialValues={{
              fromYear: now.year(),
              fromMonth: now.month(),
              fromDay: now.date(),
              fromHour: now.hour(),
              fromMinute: 0,
              toYear: inOneHour.year(),
              toMonth: inOneHour.month(),
              toDay: inOneHour.date(),
              toHour: inOneHour.hour(),
              toMinute: 0,
            }}
            onSubmit={onSubmit}
            render={({values, handleSubmit}) => {
              return (
                <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                  <div className="grid grid-cols-[50px_1fr_1fr] gap-4 items-center">
                    <div className="w-12" />
                    <Components.Label className="w-12">From</Components.Label>
                    <Components.Label className="w-12">To</Components.Label>

                    <Components.Label className="w-24">Year</Components.Label>
                    <Fields.SelectField name="fromYear" options={YEAR_OPTIONS} size="sm" expanded />
                    <Fields.SelectField name="toYear" options={YEAR_OPTIONS} size="sm" expanded />

                    <Components.Label className="w-32">Month</Components.Label>
                    <Fields.SelectField name="fromMonth" options={MONTH_OPTIONS} size="sm" expanded />
                    <Fields.SelectField name="toMonth" options={MONTH_OPTIONS} size="sm" expanded />

                    <Components.Label className="w-20">Date</Components.Label>
                    <Fields.SelectField name="fromDay" options={getDayOptions(values.year, values.month)} size="sm" expanded />
                    <Fields.SelectField name="toDay" options={getDayOptions(values.year, values.month)} size="sm" expanded />

                    <Components.Label className="w-20">Hour</Components.Label>
                    <Fields.SelectField name="fromHour" options={HOUR_OPTIONS} size="sm" expanded />
                    <Fields.SelectField name="toHour" options={HOUR_OPTIONS} size="sm" expanded />

                    <Components.Label className="w-20">Minute</Components.Label>
                    <Fields.SelectField name="fromMinute" options={MINUTE_OPTIONS} size="sm" expanded />
                    <Fields.SelectField name="toMinute" options={MINUTE_OPTIONS} size="sm" expanded />
                  </div>

                  <Fields.TextAreaField label="Comment" name="comment" size="sm" expanded rows={5} />

                  <Components.Button color="green" size="sm" className="ml-auto" type="submit" expanded>
                    Add
                  </Components.Button>
                </form>
              )
            }}
          />
        </Components.Modal>
      )}
    </>
  )
}
