import cx from 'classnames'
import {ReactNode} from 'react'
import {BorderStyle, CuttedBorder} from './CuttedBorder'
import {FocusMark} from './FocusMark'

type Props = {
  borderColor: string
  backgroundColor: string
  foregroundColor: string
  borderStyle: BorderStyle
  startCut?: boolean
  endCut?: boolean
  children: ReactNode
  onClick?: () => void
  focus?: boolean
  height: number
  outdated?: boolean
}

export const HorizontalShiftFrame = ({
  borderColor,
  borderStyle,
  backgroundColor,
  foregroundColor,
  startCut,
  endCut,
  children,
  onClick,
  focus,
  height,
  outdated,
}: Props) => {
  const cuttedBorderHeight = height - 2 * 2
  return (
    <div
      className={cx('cursor-pointer w-full flex border-y-2', {
        'border-l-2': !startCut,
        'border-r-2': !endCut,
      })}
      style={{
        height,
        borderColor,
        borderStyle,
        backgroundColor: foregroundColor,
      }}
    >
      {startCut && (
        <CuttedBorder backgroundColor={backgroundColor} borderColor={borderColor} edge="left" style={borderStyle} size={cuttedBorderHeight} />
      )}
      <div
        className={cx('flex items-center h-full', {
          'w-full': !endCut && !startCut,
          'w-[calc(100%-10px)]': endCut || startCut,
          'w-[calc(100%-10px-10px)]': endCut && startCut,
        })}
        onClick={onClick}
      >
        {focus && <FocusMark className="p-0.5" />}
        {children}
      </div>
      {endCut && (
        <CuttedBorder backgroundColor={backgroundColor} borderColor={borderColor} edge="right" style={borderStyle} size={cuttedBorderHeight} />
      )}
    </div>
  )
}
