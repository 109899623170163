import {Params, Responses} from 'common/types/guidesStatuses'
import {Utils} from 'ui'
import {axiosInstance} from './getInstance'

export const crudApi = new Utils.CrudApi<
  never,
  Params.List,
  Params.Create,
  never,
  Responses.List,
  never,
  Responses.Create,
  never
>('/guidesStatuses', axiosInstance)
