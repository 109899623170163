import cx from 'classnames'
import {Assignments, Locations, Products, Shifts, Unavailabilities} from 'common/types'
import {DayButton} from 'components/DayButton'
import {ShiftAssignmentInterval} from 'components/intervals/vertical/ShiftAssignmentInterval'
import {UnavailabilityInterval} from 'components/intervals/vertical/UnavailabilityInterval'
import {ShiftAssignmentViewer} from 'components/viewers/ShiftAssignmentViewer'
import {UnavailabilityViewer} from 'components/viewers/UnavailabilityViewer'
import {groupBy, keyBy, keys, range} from 'lodash-es'
import moment from 'moment'
import {useMemo, useState} from 'react'
import {getConflictedIntervalGroups} from 'utils/getConflictedIntervalGroups'
import {IntervalInfo, getIntervals} from 'utils/getIntervals'
import {IntervalLike} from '../../../utils/getIntervals'
import {VerticalIntervalCalendar} from '../basic/VerticalIntervalCalendar'

type Props = {
  startOfWeek: string
  shifts: Shifts.Responses.List
  assignments: Assignments.Basic.StorableEntity[]
  unavailabilities: Unavailabilities.Basic.StorableEntity[]
  products: Products.Basic.StorableEntity[]
  locations: Locations.Basic.StorableEntity[]
  onChange: () => void
}

type IntervalIdentifier = {
  entity: string
  entityId: string
}

export const WeekCalendar = ({
  startOfWeek: startOfPeriod, // any 7 days
  shifts,
  assignments,
  products,
  locations,
  unavailabilities,
  onChange,
}: Props) => {
  const now = moment()
  const currentDay = now.day()
  const periodStartDay = moment(startOfPeriod).day()
  const mStartOfPeriod = moment.utc(startOfPeriod)
  const mEndOfPeriod = moment.utc(mStartOfPeriod).add(7, 'days')
  const containsCurrentDate = now.isBetween(mStartOfPeriod, mEndOfPeriod)
  const currentPeriodDay = containsCurrentDate ? currentDay - periodStartDay : -1
  const initiallySelectedPeriodDay = currentPeriodDay >= 0 ? currentDay - periodStartDay : 0
  const [periodDay, setPeriodDay] = useState(initiallySelectedPeriodDay)

  const shiftMap = keyBy(shifts, 'id')
  const shiftAssignmentMap = keyBy(assignments, 'shiftId')
  const unavailabilityMap = keyBy(unavailabilities, 'id')
  const productMap = keyBy(products, 'id')
  const locationMap = keyBy(locations, 'id')

  const [focusedInterval, setFocusedInterval] = useState<IntervalIdentifier | null>(null)
  const [displayedInterval, setDisplayedInterval] = useState<IntervalIdentifier | null>(null)

  const memoizedIntervalComponent = useMemo(() => {
    return function IntervalComponent(interval: IntervalInfo & {width: number}) {
      if (interval.entity === 'empty') {
        return <div className={cx('bg-transparent')} />
      }

      if (interval.entityId === undefined) {
        throw new Error('Entity id is not defined')
      }

      const intervalEntityId = interval.entityId

      if (interval.entity === 'unavailability') {
        const unavailability = unavailabilityMap[intervalEntityId]

        return (
          <UnavailabilityInterval
            startCut={interval.startOverflow}
            endCut={interval.endOverflow}
            key={unavailability.id}
            unavailability={unavailability}
            width={interval.width}
            focus={Boolean(focusedInterval && focusedInterval.entity === interval.entity && unavailability.id === focusedInterval.entityId)}
            onClick={() => {
              setFocusedInterval({entity: interval.entity, entityId: intervalEntityId})
              setDisplayedInterval({entity: interval.entity, entityId: intervalEntityId})
            }}
          />
        )
      }

      const shift = shiftMap[intervalEntityId]

      return (
        <ShiftAssignmentInterval
          startCut={interval.startOverflow}
          endCut={interval.endOverflow}
          key={shift.id}
          shift={shift}
          product={productMap[shift.productId]}
          width={interval.width}
          focus={Boolean(focusedInterval && focusedInterval.entity === interval.entity && shift.id === focusedInterval.entityId)}
          onClick={() => {
            setFocusedInterval({entity: interval.entity, entityId: intervalEntityId})
            setDisplayedInterval({entity: interval.entity, entityId: intervalEntityId})
          }}
        />
      )
    }
  }, [shifts, unavailabilities, focusedInterval])

  const dayGroups: {
    shifts: IntervalLike[]
    unavailabilities: IntervalLike[]
    startOfDay: string
    endOfDay: string
  }[] = []

  for (let i = 0; i < 7; i++) {
    const mStartOfDateUtc = moment.utc(startOfPeriod).add(i, 'days')
    const startOfDay = mStartOfDateUtc.format()
    const endOfDay = moment.utc(mStartOfDateUtc).add(1, 'day').format()

    const dayShifts = shifts
      .filter((shift) => {
        return (
          // (shift.startDateTime >= startOfDay && shift.startDateTime < endOfDay) ||
          // (shift.endDateTime >= startOfDay && shift.endDateTime < endOfDay) ||
          // (shift.startDateTime < startOfDay && shift.endDateTime > endOfDay)
          true
        )
      })
      .map((shift) => {
        return {
          ...shift,
          entity: 'shiftAssignment',
          entityId: shift.id,
        }
      })

    const dayUnavailabilities = unavailabilities
      .filter((unavailability) => {
        return (
          (unavailability.startedAt >= startOfDay && unavailability.startedAt < endOfDay) ||
          (unavailability.endedAt >= startOfDay && unavailability.endedAt < endOfDay) ||
          (unavailability.startedAt < startOfDay && unavailability.endedAt > endOfDay)
        )
      })
      .map((unavailability) => {
        return {
          ...unavailability,
          entity: 'unavailability',
          entityId: unavailability.id,
        }
      })

    dayGroups.push({
      startOfDay,
      endOfDay,
      // shifts: dayShifts,
      shifts: [],
      unavailabilities: dayUnavailabilities,
    })
  }

  const groupedShifts = groupBy(dayGroups[periodDay].shifts, 'productId')
  const regrouppedShifts = []

  for (const productId of keys(groupedShifts)) {
    const shiftGroup = groupedShifts[productId]
    for (const conflictedGroup of getConflictedIntervalGroups(shiftGroup)) {
      regrouppedShifts.push(conflictedGroup)
    }
  }

  const conflictedGroups = getConflictedIntervalGroups([...dayGroups[periodDay].unavailabilities, ...dayGroups[periodDay].shifts])

  const grouppedIntervals = conflictedGroups.map((group) => getIntervals(dayGroups[periodDay].startOfDay, dayGroups[periodDay].endOfDay, group))

  return (
    <div className="flex flex-col w-full">
      <div className="grid grid-cols-7 ml-12 mb-4 justify-items-center">
        {range(7).map((i) => (
          <DayButton
            key={i}
            date={moment(startOfPeriod).add(i, 'days').format('D')}
            onClick={() => setPeriodDay(i)}
            isCurrentDay={i === currentPeriodDay}
            isSelectedDay={i === periodDay}
            hasEvents={dayGroups[i].shifts.length + dayGroups[i].unavailabilities.length === 0}
          />
        ))}
      </div>

      <VerticalIntervalCalendar grouppedIntervals={grouppedIntervals} intervalComponent={memoizedIntervalComponent} />

      {displayedInterval && displayedInterval.entity === 'shiftAssignment' && (
        <ShiftAssignmentViewer
          shift={shiftMap[displayedInterval.entityId]}
          product={productMap[shiftMap[displayedInterval.entityId].productId]}
          location={locationMap[productMap[shiftMap[displayedInterval.entityId].productId].locationId]}
          onClose={() => {
            setDisplayedInterval(null)
          }}
          shiftAssignment={shiftAssignmentMap[displayedInterval.entityId]}
          onChange={onChange}
        />
      )}
      {displayedInterval && displayedInterval.entity === 'unavailability' && (
        <UnavailabilityViewer
          unavailability={unavailabilityMap[displayedInterval.entityId]}
          onClose={() => {
            setDisplayedInterval(null)
          }}
          onChange={onChange}
        />
      )}
    </div>
  )
}
