import {productHooks} from 'hooks/products'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Components} from 'ui'

export const List = () => {
  const navigate = useNavigate()

  const [offset, setOffset] = useState(0)

  const productListQuery = productHooks.useListQuery({
    ordering: ['createdAt'],
    filters: [{field: 'isDeleted', value: false}],
    offset,
  })

  const productCountQuery = productHooks.useCountQuery({
    filters: [{field: 'isDeleted', value: false}],
  })

  const onAddButtonClick = () => {
    navigate('/products/create')
  }

  const headers = [
    {key: 'name', value: 'Name'},
    {key: 'code', value: 'Code'},
    {key: 'ratio', value: 'Ratio'},
    {key: 'color', value: 'Color'},
    {key: 'edit'},
  ]

  console.log(productListQuery.data, productCountQuery.data)

  if (!productListQuery.data || !productCountQuery.data) {
    return null
  }

  console.log(productCountQuery.data)

  const rows = productListQuery.data.map((product) => ({
    key: product.id,
    columns: {
      name: product.name,
      code: product.code,
      ratio: product.ratio,
      color: (
        <div className="rounded-full text-xs font-semibold text-white w-fit px-2 py-1" style={{backgroundColor: product.color}}>
          {product.color}
        </div>
      ),
      edit: <Components.EditLink to={`/products/${product.id}/edit`} />,
    },
  }))

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Components.Header.H5>Products</Components.Header.H5>
        <Components.Button onClick={onAddButtonClick}>Add</Components.Button>
      </div>

      <Components.Table headers={headers} rows={rows} />
      <Components.Paginator total={productCountQuery.data} onChangeOffset={setOffset} />
    </div>
  )
}
