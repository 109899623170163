import {GuidesStatuses} from 'common/types'
import {guideHooks} from 'hooks/guides'
import {guidesStatusesHooks} from 'hooks/guidesStatuses'
import {locationHooks} from 'hooks/locations'
import {productHooks} from 'hooks/products'
import {useId} from 'hooks/useId'
import {userHooks} from 'hooks/users'
import {isEmpty} from 'lodash-es'
import moment, {Moment} from 'moment'
import {Form} from 'react-final-form'
import {Components, Fields} from 'ui'
import {Frame} from 'ui/components/Frame'

const STATUS_OPTIONS = [
  {label: 'New', value: 'new'},
  {label: 'Regular', value: 'regular'},
  {label: 'Top achiever', value: 'top_achiever'},
]

const getLastMonthStatus = (statusHistory: GuidesStatuses.Basic.Entity[], from: Moment, to: Moment) => {
  let lastMonthStatusStatusHistoryItem: GuidesStatuses.Basic.Entity | null = null

  for (const statusHistoryItem of statusHistory) {
    if (!moment(statusHistoryItem.createdAt).isBetween(from, to)) {
      continue
    }

    if (!lastMonthStatusStatusHistoryItem || lastMonthStatusStatusHistoryItem.createdAt < statusHistoryItem.createdAt) {
      lastMonthStatusStatusHistoryItem = statusHistoryItem
    }
  }

  return lastMonthStatusStatusHistoryItem?.status
}

const getMontlyStatusHistory = (statusHistory: GuidesStatuses.Basic.Entity[]) => {
  const now = moment()
  const firstDateOfCurrentMonth = moment(now).date(1).startOf('day')
  const monthlyStatusHistory = []

  for (let i = 0; i < 5; i++) {
    const from = moment(firstDateOfCurrentMonth).subtract(i, 'month')
    const to = moment(from).add(1, 'month')

    monthlyStatusHistory.push({
      month: from.format('MMMM'),
      status: getLastMonthStatus(statusHistory, from, to),
    })
  }

  return monthlyStatusHistory.reverse()
}

export const Edit = () => {
  const id = useId()
  const itemQuery = guideHooks.useItemQuery(id)

  const locationsQuery = locationHooks.useListQuery({
    ordering: ['name'],
  })

  const productsQuery = productHooks.useListQuery({
    ordering: ['name'],
  })

  const guidesSatusesQuery = guidesStatusesHooks.useListQuery({
    filters: [{field: 'guideId', value: id}],
    ordering: ['createdAt'],
  })

  const updateUserDataMutation = userHooks.useUpdateMutation()
  const updateGuideDataMutation = guideHooks.useUpdateMutation()
  const pushGuideStatus = guidesStatusesHooks.useCreateMutation()

  const updateUserData = (values: any) => {
    updateUserDataMutation.mutate(values, {
      onSuccess() {
        itemQuery.refetch()
      },
    })
  }

  const updateGuideData = (values: any) => {
    updateGuideDataMutation.mutate(values, {
      onSuccess() {
        itemQuery.refetch()
      },
    })
  }

  const updateGuideStatus = (values: any) => {
    pushGuideStatus.mutate(values, {
      onSuccess() {
        guidesSatusesQuery.refetch()
      },
    })
  }

  if (!itemQuery.data || !locationsQuery.data || !productsQuery.data || !guidesSatusesQuery.data) {
    return null
  }

  const locationOptions = locationsQuery.data.map((location) => {
    return {
      label: location.name,
      value: location.id,
    }
  })

  const productOptions = productsQuery.data
    .filter((product) => {
      return itemQuery.data.locationIds.includes(product.locationId)
    })
    .map((product) => {
      return {
        label: product.shortName,
        value: product.id,
      }
    })

  const monthlyStatusHistory = getMontlyStatusHistory(guidesSatusesQuery.data)

  return (
    <div className="flex flex-col gap-12">
      <Components.Header.H5>{`Guide ${itemQuery.data.firstName} ${itemQuery.data.lastName}`}</Components.Header.H5>

      <Frame title="User info">
        <Form
          initialValues={itemQuery.data}
          onSubmit={updateUserData}
          render={({handleSubmit, pristine, values}) => {
            const disableSave =
              pristine ||
              isEmpty(values.firstName) ||
              isEmpty(values.lastName) ||
              isEmpty(values.email) ||
              isEmpty(values.locationIds) ||
              isEmpty(values.roles)

            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <Fields.InputField label="First name" name="firstName" expanded />
                <Fields.InputField label="Last name" name="lastName" expanded />
                <Fields.InputField label="Email" name="email" expanded />
                <Fields.MultiSelectField label="Locations" name="locationIds" options={locationOptions} expanded />

                <Components.Button disabled={disableSave} className="ml-auto">
                  Save
                </Components.Button>
              </form>
            )
          }}
        />
      </Frame>
      <Frame title="Guide info">
        <Form
          initialValues={itemQuery.data}
          onSubmit={updateGuideData}
          render={({handleSubmit, pristine, values}) => {
            const disableSave = pristine || isEmpty(values.productIds)

            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <Fields.MultiSelectField label="Products" name="productIds" options={productOptions} expanded />

                <Components.Button disabled={disableSave} className="ml-auto">
                  Save
                </Components.Button>
              </form>
            )
          }}
        />
      </Frame>
      <Frame title="Performance status history">
        <Form
          initialValues={{status: itemQuery.data.status, guideId: itemQuery.data.id}}
          onSubmit={updateGuideStatus}
          render={({handleSubmit, pristine, values}) => {
            const disableSave = pristine || isEmpty(values.status)

            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      {monthlyStatusHistory.map((statusHistoryItem) => {
                        return (
                          <th key={statusHistoryItem.month} className="px-6 py-3">
                            {statusHistoryItem.month}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {monthlyStatusHistory.slice(0, 4).map((statusHistoryItem) => {
                        return (
                          <td key={statusHistoryItem.month} className="px-6 py-4">
                            {statusHistoryItem.status || 'No'}
                          </td>
                        )
                      })}
                      <td className="px-6 py-4">
                        <Fields.SelectField name="status" options={STATUS_OPTIONS} expanded />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Components.Button disabled={disableSave} className="ml-auto">
                  Save
                </Components.Button>
              </form>
            )
          }}
        />
      </Frame>
    </div>
  )
}
