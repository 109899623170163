import {locationHooks} from 'hooks/locations'
import {productHooks} from 'hooks/products'
import {isEmpty} from 'lodash-es'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {Components, Fields} from 'ui'

export const Create = () => {
  const navigate = useNavigate()
  const locationsQuery = locationHooks.useListQuery({})

  const createMutation = productHooks.useCreateMutation()

  const onSubmit = (values: any) => {
    createMutation.mutate(
      {...values},
      {
        onSuccess(location) {
          navigate(`/activities/${location.id}/edit`)
        },
      }
    )
  }

  if (!locationsQuery.data) {
    return null
  }

  const locationOptions = locationsQuery.data.map((location) => {
    return {
      label: location.name,
      value: location.id,
    }
  })

  return (
    <div className="flex flex-col gap-6">
      <Components.Header.H5>Add product</Components.Header.H5>

      <Form
        onSubmit={onSubmit}
        render={({handleSubmit, pristine, values}) => {
          const disableSave = pristine || isEmpty(values.name) || isEmpty(values.locationId) || isEmpty(values.code) || isEmpty(values.color)
          isEmpty(values.ratio)

          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="Name" name="name" expanded />
              <Fields.SelectField label="Location" name="locationId" options={locationOptions} expanded />
              <Fields.InputField label="Code" name="code" expanded />
              <Fields.InputField label="Color" name="color" expanded />
              <Fields.InputField label="Ratio" name="ratio" type="number" expanded />

              <Components.Button disabled={disableSave} className="ml-auto">
                Save
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
