import cx from 'classnames'

type Props = {
  onClick: () => void
  date: string
  isSelectedDay: boolean
  isCurrentDay: boolean
  hasEvents: boolean
}

export const DayButton = ({date, onClick, isSelectedDay, isCurrentDay, hasEvents}: Props) => {
  return (
    <div className={cx('flex border-4 border-transparent', {'border-b-blue-500': isCurrentDay})}>
      <div
        className={cx(
          'm-1 w-8 h-8 font-semibold text-xs rounded-full flex justify-center items-center border border-2 border-blue-300',
          hasEvents ? 'bg-white text-blue-300' : 'bg-blue-300 text-white',
          isSelectedDay ? 'bg-blue-500 border-blue-500' : 'cursor-pointer hover:opacity-70'
        )}
        onClick={onClick}
      >
        {date}
      </div>
    </div>
  )
}
