import axios from 'axios'

const token = window.localStorage.getItem('token')
const headers = token
  ? {
      Authorization: `Bearer ${token}`,
    }
  : undefined

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: '/api/v1',
  headers,
})
