import {ShiftFrame} from 'components/shift/ShiftFrame'
import {ReactNode} from 'react'
import {BorderStyle} from '../../shift/CuttedBorder'

type Props = {
  borderColor: string
  backgroundColor: string
  foregroundColor: string
  borderStyle: BorderStyle
  startCut?: boolean
  endCut?: boolean
  children: ReactNode
  onClick?: () => void
  focus?: boolean
  width: number
  outdated?: boolean
}

export const VerticalInterval = ({
  borderColor,
  borderStyle,
  backgroundColor,
  foregroundColor,
  startCut,
  endCut,
  children,
  onClick,
  focus,
  width,
  outdated,
}: Props) => {
  return (
    <ShiftFrame
      borderColor={borderColor}
      borderStyle={borderStyle}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      startCut={startCut}
      endCut={endCut}
      onClick={onClick}
      orientation="vertical"
      focus={focus}
      fixedSideSize={width}
      outdated={outdated}
    >
      {children}
    </ShiftFrame>
  )
}
