import {iAm, signIn} from 'api'
import {AxiosError} from 'axios'
import {Users} from 'common/types'
import {Params} from 'common/types/auth'
import {useMutation, useQuery} from 'react-query'
import {Types} from 'ui'

export const useIAmQuery = (options: Types.QueryOptions<Users.Basic.JwtEntity, AxiosError>) => {
  return useQuery(['iAm'], () => iAm(), {
    cacheTime: 0,
    ...options,
  })
}

export const useSignInMutation = () => {
  return useMutation((params: Params.SignIn) => signIn(params))
}
