import {locationHooks} from 'hooks/locations'
import {useId} from 'hooks/useId'
import {isEmpty} from 'lodash-es'
import {Form} from 'react-final-form'
import {Components, Fields} from 'ui'

export const Edit = () => {
  const id = useId()
  const itemQuery = locationHooks.useItemQuery(id)

  const updateMutation = locationHooks.useUpdateMutation()

  const onSubmit = (values: any) => {
    updateMutation.mutate(values, {
      onSuccess() {
        itemQuery.refetch()
      },
    })
  }

  if (!itemQuery.data) {
    return null
  }

  return (
    <div className="flex flex-col gap-6">
      <Components.Header.H5>{`Location ${itemQuery.data.name}`}</Components.Header.H5>

      <Form
        initialValues={itemQuery.data}
        onSubmit={onSubmit}
        render={({handleSubmit, pristine, values}) => {
          const disableSave = pristine || isEmpty(values.name) || isEmpty(values.timeZone)

          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="Name" name="name" expanded />
              <Fields.InputField label="Code" name="code" expanded disabled />
              <Fields.InputField label="Time zone" name="timeZone" expanded />

              <Components.Button disabled={disableSave} className="ml-auto">
                Save
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
