import moment from 'moment'

const HOUR = 60
const DAY = 24 * HOUR

export const formatDuration = (startDateTime: string, endDateTime: string) => {
  const duration = moment.duration(moment(endDateTime).diff(startDateTime))
  const parts = []

  let tmp = duration.asMinutes()

  const days = Math.floor(tmp / DAY)

  if (days > 0) {
    parts.push(`${days}d`)
  }

  tmp = tmp - days * DAY

  const hours = Math.floor(tmp / HOUR)

  if (hours > 0) {
    parts.push(`${hours}h`)
  }

  tmp = tmp - hours * HOUR

  const minutes = tmp

  if (minutes > 0) {
    parts.push(`${minutes}m`)
  }

  return parts.join(' ')
}
