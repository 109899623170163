import {productApi} from 'api'
import {Params, Responses} from 'common/types/products'
import {Utils} from 'ui'

export const productHooks = new Utils.CrudHooks<
  Params.Count,
  Params.List,
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update
>('product', 'Product', productApi)
