import cx from 'classnames'
import { ReactNode } from 'react'

type Props = {
  title?: string
  className?: string
  children: ReactNode
}

export const Frame = ({title, className, children}: Props) => {
  return (
    <div className={cx("rounded-lg border p-4 relative", className)}>
      {title && <div className='font-bold text-sm text-gray-500 absolute top-[-10px] left-2 px-2 bg-white'>{title}</div>}
      {children}
    </div>
  )
}