import {omit, range} from 'lodash-es'
import moment from 'moment'
import {ComponentType, useEffect, useRef, useState} from 'react'
import {ExtendedIntervalInfo, IntervalInfo} from 'utils/getIntervals'

type Props = {
  grouppedIntervals: ExtendedIntervalInfo[][]
  intervalComponent: ComponentType<IntervalInfo & {width: number}>
}

export const VerticalIntervalCalendar = ({grouppedIntervals, intervalComponent: IntervalComponent}: Props) => {
  const startOfDay = moment().startOf('day')

  const containerRef = useRef<HTMLDivElement>(null)
  const [calendarWidth, setCalendarWidth] = useState(0)

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setCalendarWidth(entry.contentRect.width - (entry.contentRect.width % 10) + 2 * 2)
      }
    })

    observer.observe(containerRef.current)

    return () => {
      if (!containerRef.current) {
        return
      }
      observer.unobserve(containerRef.current)
    }
  }, [containerRef])

  return (
    <div className="w-full">
      <div className="flex">
        <div className="w-12 grid grid-rows-[repeat(24,73px)] gap-px text-xs font-semibold leading-6 text-gray-700">
          {range(0, 24).map((hour) => {
            return (
              <div className="relative" key={hour}>
                <div className="absolute right-0 top-[-10px] pr-1">{moment(startOfDay).add(hour, 'hours').format('HH:mm')}</div>
              </div>
            )
          })}
        </div>
        <div className="w-full" ref={containerRef}>
          <div className="relative bg-gray-200 border border-px border-gray-200" style={{width: calendarWidth + 2}}>
            <div className="grid grid-rows-[repeat(48,36px)] gap-px">
              {range(24 * 2).map((index) => {
                return <div key={index} className="h-full bg-white" />
              })}
            </div>
            <div className="absolute top-0 left-0 grid grid-rows-[repeat(96,1fr)] gap-px w-full h-full">
              {grouppedIntervals.map((group) => {
                return group.map((interval) => {
                  return (
                    <div key={interval.key} style={{gridRow: `span ${interval.span} / span ${interval.span}`}}>
                      <IntervalComponent {...omit(interval, 'key')} width={calendarWidth} />
                    </div>
                  )
                })
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
