import {Components} from 'ui'

export type View = 'month' | 'week' | 'day'

type Props = {
  view: View
  onChange: (view: View) => void
}

const VIEW_OPTIONS = ['month', 'week', 'day']

export const ViewSwitcher = ({view, onChange}: Props) => {
  return (
    <div className="flex gap-2 items-center">
      <Components.Label>Period</Components.Label>
      <Components.Select options={VIEW_OPTIONS} value={view} size="sm" onChange={(event) => onChange(event.target.value as View)} className="!w-24" />
    </div>
  )
}
