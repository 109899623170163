import {BorderStyle} from 'components/shift/CuttedBorder'
import {ShiftFrame} from 'components/shift/ShiftFrame'
import {ReactNode, useRef} from 'react'

const HEIGHT = 54

type Props = {
  borderColor: string
  backgroundColor: string
  foregroundColor: string
  borderStyle: BorderStyle
  startCut?: boolean
  endCut?: boolean
  children: ReactNode
  onClick?: () => void
  focus?: boolean
}

export const HorizontalInterval = ({
  borderColor,
  borderStyle,
  backgroundColor,
  foregroundColor,
  startCut,
  endCut,
  children,
  onClick,
  focus,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const onMouseEnter = () => {
    if (!wrapperRef.current || !containerRef.current) {
      return
    }

    const screenWidth = document.body.clientWidth
    const rect = wrapperRef.current.getBoundingClientRect()
    const target = containerRef.current
    const expandedWidth = 210

    target.style.minWidth = `${expandedWidth}px`
    target.style.zIndex = '5'

    if (rect.left + expandedWidth > screenWidth) {
      target.style.left = `${screenWidth - rect.left - expandedWidth}px`
    }
  }

  const onMouseLeave = () => {
    if (!containerRef.current) {
      return
    }

    const target = containerRef.current
    target.style.left = '0'
    target.style.zIndex = '0'
    target.style.minWidth = '100%'
  }

  return (
    <div ref={wrapperRef} className="relative w-full" style={{height: HEIGHT}}>
      <div className="absolute top-0 left-0 w-full h-full" ref={containerRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <ShiftFrame
          borderColor={borderColor}
          borderStyle={borderStyle}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          startCut={startCut}
          endCut={endCut}
          onClick={onClick}
          orientation="horizontal"
          focus={focus}
          fixedSideSize={HEIGHT}
        >
          {children}
        </ShiftFrame>
      </div>
    </div>
  )
}
