import {Products, Shifts} from 'common/types'
import {Storable} from 'common/types/utils'
import {formatIntervalAndDuration} from 'utils/formatIntervalAndDuration'
import {isShiftOutDated} from 'utils/isShiftOutdated'
// import {sumCounters} from 'utils/sumCounters'
import {Circle} from '../../Circle'
import {VerticalInterval} from './VerticalInterval'

type Props = {
  shift: Storable<Shifts.Basic.Entity>
  product: Storable<Products.Basic.Entity>
  onClick?: () => void
  startCut?: boolean
  endCut?: boolean
  focus?: boolean
  width: number
}

export const ShiftAssignmentInterval = ({shift, product: product, endCut, startCut, onClick, focus, width}: Props) => {
  return (
    <VerticalInterval
      borderColor={product.color}
      backgroundColor="white"
      foregroundColor={isShiftOutDated(shift) ? 'white' : '#e5e7eb'}
      borderStyle={shift.status === 'planned' ? 'dashed' : 'solid'}
      startCut={startCut}
      endCut={endCut}
      onClick={onClick}
      focus={focus}
      width={width}
      outdated={isShiftOutDated(shift)}
    >
      <div className="flex justify-between h-full w-full p-0.5 leading-[1.35] min-w-0 text-[11px]">
        <div className="w-[calc(100%-8px)]">
          <div className="flex justify-between">
            <div className="font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
              {/* {formatIntervalAndDuration(shift.startDateTime, shift.endDateTime)} */}
            </div>
            <div className="text-ellipsis whitespace-nowrap overflow-hidden">{product.name}</div>
          </div>
          {/* <div className="text-ellipsis whitespace-nowrap overflow-hidden">{shift.summary}</div> */}
        </div>
        <div className="flex flex-col gap-1">
          {/* {sumCounters(shift.guideCounters, 'required') === sumCounters(shift.guideCounters, 'accepted') && (
            <Circle className="bg-green-500" />
          )}
          {sumCounters(shift.guideCounters, 'required') !== sumCounters(shift.guideCounters, 'assigned') && (
            <Circle className="bg-orange-300" />
          )} */}
        </div>
      </div>
    </VerticalInterval>
  )
}
