import {IntervalLike} from './getIntervals'
import {isConflictedIntervals} from './isConflictedIntervals'

export const getConflictedIntervalGroups = (items: IntervalLike[]) => {
  const groups: IntervalLike[][] = []

  for (const shift of items) {
    let foundAppropriateGroup = false
    for (const group of groups) {
      if (!group.some((item) => isConflictedIntervals(item, shift))) {
        group.push(shift)
        foundAppropriateGroup = true
        break
      }
    }

    if (!foundAppropriateGroup) {
      groups.push([shift])
    }
  }

  return groups
}
