import {Users} from 'common/types'
import {UserProvider} from 'providers/UserProvider'
import {ReactNode} from 'react'

type HasRoles = {hasRole?: string} | {hasSomeOfRoles?: string[]} | {hasEveryOfRoles?: string[]}

type HasPermissions = {hasPermission?: string} | {hasSomeOfPermissions?: string[]} | {hasEveryOfPermissions?: string[]}

type Props = HasRoles &
  HasPermissions & {
    children: ReactNode
  }

const getHasPermissionsProp = (props: Props): {permissions: string[]; fn: 'every' | 'some'} => {
  if ('hasPermission' in props) {
    return {
      permissions: props.hasPermission ? [props.hasPermission] : [],
      fn: 'every',
    }
  }

  if ('hasSomeOfPermissions' in props) {
    return {
      permissions: props.hasSomeOfPermissions || [],
      fn: 'some',
    }
  }

  if ('hasEveryOfPermissions' in props) {
    return {
      permissions: props.hasEveryOfPermissions || [],
      fn: 'every',
    }
  }

  return {
    permissions: [],
    fn: 'every',
  }
}

const getHasRolesProp = (props: Props): {roles: string[]; fn: 'every' | 'some'} => {
  if ('hasRole' in props) {
    return {
      roles: props.hasRole ? [props.hasRole] : [],
      fn: 'every',
    }
  }

  if ('hasSomeOfRoles' in props) {
    return {
      roles: props.hasSomeOfRoles || [],
      fn: 'some',
    }
  }

  if ('hasEveryOfRoles' in props) {
    return {
      roles: props.hasEveryOfRoles || [],
      fn: 'every',
    }
  }

  return {
    roles: [],
    fn: 'every',
  }
}

const hasRoles = (user: Users.Basic.JwtEntity, roles: string[], fn: 'every' | 'some') => {
  return fn === 'every'
    ? roles.every((role) => user.roles.includes(role))
    : roles.some((role) => user.roles.includes(role))
}

const hasPermissions = (user: Users.Basic.JwtEntity, permissions: string[], fn: 'every' | 'some') => {
  return fn === 'every' 
  ? permissions.every((permission) => user.permissions.find((userPermission) => userPermission.startsWith(permission))) 
  : permissions.some((permission) => user.permissions.find((userPermission) => userPermission.startsWith(permission)))
}

const checkAccess = (user: Users.Basic.JwtEntity, props: Props) => {
  const hasRolesProp = getHasRolesProp(props)
  const hasPermissionsProp = getHasPermissionsProp(props)

  if (!hasRoles(user, hasRolesProp.roles, hasRolesProp.fn)) {
    return false
  }

  if (!hasPermissions(user, hasPermissionsProp.permissions, hasPermissionsProp.fn)) {
    return false
  }

  return true
}

export const AccessGuard = (props: Props) => {
  const user = UserProvider.useUser()

  if (!checkAccess(user, props)) {
    return null
  }

  return <>{props.children}</>
}
