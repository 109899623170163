import {locationHooks} from 'hooks/locations'
import {useId} from 'hooks/useId'
import {userHooks} from 'hooks/users'
import {isEmpty, pick} from 'lodash-es'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {Components, Fields} from 'ui'
import {ROLE_OPTIONS} from './Create'

export const Edit = () => {
  const navigate = useNavigate()
  const id = useId()
  const itemQuery = userHooks.useItemQuery(id)

  const locationsQuery = locationHooks.useListQuery({ordering: ['name']})

  const updateMutation = userHooks.useUpdateMutation()

  const onSubmit = (values: any) => {
    updateMutation.mutate(
      {...values},
      {
        onSuccess() {
          itemQuery.refetch()
        },
      }
    )
  }

  const onEditButtonClick = () => {
    navigate(`/guides/${id}/edit`)
  }

  const onCloneButtonClick = () => {
    if (!itemQuery.data) {
      throw new Error(`Can't access user data`)
    }

    navigate(`/users/create?clone=${encodeURIComponent(JSON.stringify(pick(itemQuery.data, 'roles', 'locationIds')))}`)
  }

  if (!itemQuery.data || !locationsQuery.data) {
    return null
  }

  const locationOptions = locationsQuery.data.map((location) => {
    return {
      label: location.name,
      value: location.id,
    }
  })

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between">
        <Components.Header.H5>{`User ${itemQuery.data.firstName} ${itemQuery.data.lastName}`}</Components.Header.H5>
        {itemQuery.data.roles.includes('guideUser') && (
          <div className="flex gap-4">
            <Components.Button outline onClick={onCloneButtonClick}>
              Clone user
            </Components.Button>
            <Components.Button outline onClick={onEditButtonClick}>
              Edit guide
            </Components.Button>
          </div>
        )}
      </div>

      <Form
        initialValues={itemQuery.data}
        onSubmit={onSubmit}
        render={({handleSubmit, pristine, values}) => {
          const disableSave =
            pristine || isEmpty(values.firstName) || isEmpty(values.lastName) || isEmpty(values.email) || isEmpty(values.locationIds) || isEmpty(values.roles)

          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="First name" name="firstName" expanded />
              <Fields.InputField label="Last name" name="lastName" expanded />
              <Fields.InputField label="Email" name="email" expanded />
              <Fields.MultiSelectField label="Roles" name="roles" options={ROLE_OPTIONS} expanded />
              <Fields.MultiSelectField label="Locations" name="locationIds" options={locationOptions} expanded />

              <Components.Button disabled={disableSave} className="ml-auto">
                Save
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
