import {YEAR_OPTIONS} from 'consts'
import {Components} from 'ui'

type Props = {
  year: number
  onChange: (year: number) => void
}

export const YearFilter = ({year, onChange}: Props) => {
  return (
    <div className="flex gap-2 items-center">
      <Components.Select
        options={YEAR_OPTIONS}
        value={year.toString()}
        size="sm"
        onChange={(event) => onChange(parseInt(event.target.value, 10))}
        className="!w-20"
      />
    </div>
  )
}
