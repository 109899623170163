import cx from 'classnames'
import {Locations, Products, Shifts} from 'common/types'
import {ShiftInterval} from 'components/intervals/horizontal/ShiftInterval'
import {ShiftViewer} from 'components/viewers/ShiftViewer'
import {WEEK_DAYS} from 'consts'
import {get, groupBy, keyBy, range} from 'lodash-es'
import moment from 'moment'
import {useState} from 'react'
import {pluralize} from 'utils/pluralize'

type Props = {
  year: number
  month: number
  week: string
  shifts: Shifts.Basic.AugmentedEntity[]
  products: Products.Basic.StorableEntity[]
  locations: Locations.Basic.StorableEntity[]
  onChange: () => void
}

const groupShifts = (shifts: Shifts.Responses.List) => {
  return groupBy(shifts, (shift) => moment(shift.startedAt).date())
}

export const WeekCalendar = ({year, month, week, shifts, products, locations, onChange}: Props) => {
  const now = moment()
  const groupedShifts = groupShifts(shifts)
  const shiftMap = keyBy(shifts, 'id')
  const productMap = keyBy(products, 'id')
  const locationMap = keyBy(locations, 'id')
  const [focusedShiftId, setFocusedShiftId] = useState('')
  const [displayedShiftId, setDisplayedShiftId] = useState('')

  const startDateTime = week.split('|')[0]

  return (
    <div>
      <div className="grid grid-cols-7 border-y border-gray-300 bg-gray-300 gap-px">
        {WEEK_DAYS.map((day) => {
          return (
            <div className="text-center relative bg-white p-1 text-xs font-semibold leading-6 text-gray-700" key={day}>
              {day}
            </div>
          )
        })}
        {range(7).map((i) => {
          const day = moment(startDateTime).add(i, 'days').date()
          const dayShifts = get(groupedShifts, day, [])
          return (
            <div className="relative bg-white p-1 min-h-28" key={`${year} ${month} ${day}`}>
              <div className="flex justify-between">
                <div
                  className={cx('text-xs text-gray-700 w-6 h-6 flex items-center justify-center mb-2', {
                    'bg-blue-500 rounded-full text-white': year === now.year() && month === now.month() && day === now.date(),
                  })}
                >
                  {day}
                </div>
                {dayShifts.length > 0 && (
                  <div className="text-[11px] font-semibold">
                    {dayShifts.length} {pluralize(dayShifts.length, 'shift', 'shifts')}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-px">
                {dayShifts.map((shift) => {
                  return (
                    <ShiftInterval
                      key={shift.id}
                      shift={shift}
                      product={productMap[shift.productId]}
                      focus={shift.id === focusedShiftId}
                      onClick={() => {
                        setFocusedShiftId(shift.id)
                        setDisplayedShiftId(shift.id)
                      }}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      {displayedShiftId && (
        <ShiftViewer
          shift={shiftMap[displayedShiftId]}
          product={productMap[shiftMap[displayedShiftId].productId]}
          location={locationMap[productMap[shiftMap[displayedShiftId].productId].locationId]}
          onClose={() => setDisplayedShiftId('')}
          onChange={onChange}
        />
      )}
    </div>
  )
}
