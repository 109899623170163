import {guideApi} from 'api'
import {CrudApi} from 'api/guides'
import {Params, Responses} from 'common/types/guides'
import {useQuery} from 'react-query'
import {Utils} from 'ui'

class CrudHooks extends Utils.CrudHooks<
  Params.Count,
  Params.List,
  never,
  Params.Update,
  Responses.List,
  Responses.Item,
  never,
  Responses.Update,
  CrudApi
> {
  useAllowedForAssignmentQuery(params: Params.AllowedForAssignment) {
    return useQuery<Responses.AllowedForAssignment>([this.entityKey, 'allowedForAssignment', params], () => this.crudApi.allowedForAssignment(params))
  }

  useAssignedToShiftQuery(params: Params.AssignedToShift) {
    return useQuery<Responses.AssignedToShift>([this.entityKey, 'assignedToShift', params], () => this.crudApi.assignedToShift(params))
  }
}

export const guideHooks = new CrudHooks('guide', 'Guide', guideApi)
