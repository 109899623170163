import {guideHooks} from 'hooks/guides'
import {useNavigate} from 'react-router-dom'
import {Components} from 'ui'

export const List = () => {
  const navigate = useNavigate()

  const paginatedQuery = guideHooks.useListQuery({
    limit: 50,
    ordering: ['createdAt'],
    filters: [{field: 'isDeleted', value: false}],
  })

  const onAddButtonClick = () => {
    navigate('/guides/create')
  }

  if (!paginatedQuery.data) {
    return null
  }

  const headers = [{key: 'name', value: 'Name'}, {key: 'email', value: 'Email'}, {key: 'edit'}]

  const rows = paginatedQuery.data.map((user) => {
    return {
      key: user.id,
      columns: {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        edit: <Components.EditLink to={`/guides/${user.id}/edit`} />,
      },
    }
  })

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Components.Header.H5>Guides</Components.Header.H5>
        <Components.Button onClick={onAddButtonClick}>Add</Components.Button>
      </div>

      <Components.Table headers={headers} rows={rows} />
    </div>
  )
}
