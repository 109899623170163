import {AxiosInstance, AxiosResponse} from 'axios'

export class CrudApi<
  CountParams extends object,
  ListParams extends object,
  CreateParams extends object,
  UpdateParams extends {id: string},
  ListResponse extends unknown[],
  ItemResponse,
  CreateResponse,
  UpdateResponse
> {
  constructor(protected readonly entityApiPath: string, protected readonly axiosInstance: AxiosInstance) {}

  async list(params: ListParams): Promise<ListResponse> {
    const result = await this.axiosInstance.post<ListResponse>(`${this.entityApiPath}/list`, params)
    return result.data
  }

  async count(params: CountParams): Promise<number> {
    const result = await this.axiosInstance.post<number>(`${this.entityApiPath}/count`, params)
    return result.data
  }

  async item(id: string): Promise<ItemResponse> {
    const result = await this.axiosInstance.get<ItemResponse>(`${this.entityApiPath}/${id}`)
    return result.data
  }

  async create(params: CreateParams): Promise<CreateResponse> {
    const result = await this.axiosInstance.post<CreateResponse>(this.entityApiPath, params)
    return result.data
  }

  async update(params: UpdateParams): Promise<UpdateResponse> {
    const result = await this.axiosInstance.patch<UpdateResponse>(`${this.entityApiPath}/${params.id}`, params)
    return result.data
  }

  async remove(id: string): Promise<void> {
    await this.axiosInstance.delete<unknown, AxiosResponse<unknown>>(`${this.entityApiPath}/${id}`)
  }
}
