import moment from 'moment'
import {useEffect} from 'react'
import {DayFilter} from './DayFilter'
import {MonthFilter} from './MonthFilter'
import {YearFilter} from './YearFilter'

type DayViewFiltersValue = {
  year: number
  month: number
  day: number
}

type Props = {
  value: DayViewFiltersValue
  onChange: (value: DayViewFiltersValue) => void
}

export const getDefaultDayViewFilters = (): DayViewFiltersValue => {
  const now = moment()

  return {
    year: now.year(),
    month: now.month(),
    day: now.date(),
  }
}

export const DayViewFilters = ({value, onChange}: Props) => {
  useEffect(() => {
    const daysInMonth = moment({year: value.year, month: value.month}).daysInMonth()
    onChange({...value, day: value.day <= daysInMonth ? value.day : daysInMonth})
  }, [value.year, value.month])

  return (
    <div className="flex gap-2 items-center">
      <YearFilter year={value.year} onChange={(year) => onChange({...value, year})} />
      <MonthFilter month={value.month} onChange={(month) => onChange({...value, month})} />
      <DayFilter year={value.year} month={value.month} day={value.day} onChange={(day) => onChange({...value, day})} />
    </div>
  )
}
