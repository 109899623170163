import cx from 'classnames'
import {Locations, Products, Shifts} from 'common/types'
import {Storable} from 'common/types/utils'
import {bookingHooks} from 'hooks/bookings'
import {Pill} from './Pill'

type Props = {
  shift: Storable<Shifts.Basic.AugmentedEntity>
  product: Products.Basic.Entity
  location: Locations.Basic.Entity
  className?: string
  showStatus?: boolean
  showGuides?: boolean
}

const getWidth = (part: number, total: number) => {
  return total === 0 ? 0 : `${(part / total) * 100}%`
}

export const ShiftInfo = ({shift, product, location, className, showStatus, showGuides}: Props) => {
  const bookingsQuery = bookingHooks.useListQuery({
    filters: [{field: 'shiftId', value: shift.id}],
  })

  const accepted = shift.acceptedAssignmentQty
  const required = shift.requiredGuideQty
  const assigned = shift.assignedGuideQty
  const total = Math.max(required, assigned)

  return (
    <div className={cx('flex flex-col text-sm w-full', className)}>
      <div className="flex flex-col min-h-0">
        <div className="flex flex-col divide-y divide-gray-300">
          <div className="flex justify-between pb-3">
            <div className="text-gray-500">Location</div>
            <div className="text-gray-700">{location.name}</div>
          </div>

          <div className="flex justify-between py-3">
            <div className="text-gray-500">Product</div>
            <Pill style={{backgroundColor: product.color}}>{product.shortName}</Pill>
          </div>

          {showStatus && (
            <div className="flex justify-between py-3">
              <div className="text-gray-500">Status</div>
              <div className="text-gray-700">{shift.status}</div>
            </div>
          )}

          {showGuides && (
            <>
              <div className="flex justify-between py-3 gap-3">
                <div className="text-gray-500 min-w-[80px]">Assignment</div>
                <div className="border border-gray-400 w-full relative text-white h-5 text-xs leading-5 text-center">
                  <div
                    className="inline-block h-full bg-green-500 overflow-hidden text-ellipsis text-nowrap"
                    title={`Assigned ${Math.min(assigned, required)}/${required}`}
                    style={{width: getWidth(Math.min(assigned, required), total)}}
                  >
                    {Math.min(assigned, required)}/{required} assigned
                  </div>
                  <div
                    className="inline-block h-full bg-orange-300  overflow-hidden text-ellipsis text-nowrap"
                    title={`Not assigned ${total - assigned}/${required}`}
                    style={{width: getWidth(total - assigned, total)}}
                  >
                    {total - assigned}/{required} not assigned
                  </div>
                  <div
                    className="inline-block h-full bg-purple-500 overflow-hidden text-ellipsis text-nowrap"
                    title={`Extra ${total - required}`}
                    style={{width: getWidth(total - required, total)}}
                  >
                    {total - required} extra
                  </div>
                </div>
              </div>
              <div className="flex justify-between py-3 gap-3">
                <div className="text-gray-500 min-w-[80px]">Reactions</div>
                <div className="border border-gray-400 w-full relative text-white h-5 text-xs leading-5 text-center">
                  <div
                    className="inline-block h-full bg-green-500 overflow-hidden text-ellipsis text-nowrap"
                    title={`Accepted ${accepted}/${total}`}
                    style={{width: getWidth(accepted, total)}}
                  >
                    {accepted}/{total} accepted
                  </div>
                  <div
                    className="inline-block h-full bg-pink-400 overflow-hidden text-ellipsis text-nowrap"
                    title={`Pending ${assigned - accepted}/${total}`}
                    style={{width: getWidth(assigned - accepted, total)}}
                  >
                    {assigned - accepted}/{total} pending
                  </div>
                  <div
                    className="inline-block h-full bg-orange-300 overflow-hidden text-ellipsis text-nowrap"
                    title={`Pending ${total - assigned}/${total}`}
                    style={{width: getWidth(total - assigned, total)}}
                  >
                    {total - assigned}/{total} not assigned
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {bookingsQuery.data && (
          <div className="mt-3 grow flex flex-col min-h-0">
            <div className="text-gray-500">Details</div>
            <div className="overflow-auto">
              {bookingsQuery.data.map((booking) => {
                return (
                  <div key={booking.id} className="flex gap-2">
                    <div className="flex flex-col">
                      <div>{booking.supplierReference}</div>
                      <div className="text-xs">
                        {booking.adultQty} adults / {booking.childQty} children
                      </div>
                    </div>
                    <div className="flex gap-1 font-bold">
                      <div className="">{booking.lastName.toUpperCase()}</div>
                      <div>{booking.firstName}</div>
                    </div>
                    <div className="flex flex-col">
                      <div>{booking.emailAddress}</div>
                      <div>{booking.phoneNumber}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
