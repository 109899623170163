import {omit} from 'lodash-es'
import {useMutation, useQuery} from 'react-query'
import {ToasterProvider} from '../providers'
import {QueryOptions} from '../types'
import {CrudApi} from './CrudApi'

type WithAdditionalQueryKeyPart<T> = T & {additionalQueryKeyPart?: any}

export class CrudHooks<
  CountParams extends object,
  ListParams extends object,
  CreateParams extends object,
  UpdateParams extends {id: string},
  ListResponse extends unknown[],
  ItemResponse,
  CreateResponse,
  UpdateResponse,
  CrudApiInstance extends CrudApi<
    CountParams,
    ListParams,
    CreateParams,
    UpdateParams,
    ListResponse,
    ItemResponse,
    CreateResponse,
    UpdateResponse
  > = CrudApi<CountParams, ListParams, CreateParams, UpdateParams, ListResponse, ItemResponse, CreateResponse, UpdateResponse>
> {
  constructor(protected readonly entityKey: string, protected readonly entityName: string, protected readonly crudApi: CrudApiInstance) {}

  useCreateMutation() {
    const add = ToasterProvider.useToasterMessageAdder()
    return useMutation<CreateResponse, unknown, CreateParams>((params: CreateParams) => this.crudApi.create(params), {
      onSuccess: () => {
        add({
          message: `${this.entityName} added`,
          type: 'success',
        })
      },
    })
  }

  useUpdateMutation() {
    const add = ToasterProvider.useToasterMessageAdder()
    return useMutation<UpdateResponse, unknown, UpdateParams>((params: UpdateParams) => this.crudApi.update(params), {
      onSuccess: () => {
        add({
          message: `${this.entityName} updated`,
          type: 'success',
        })
      },
    })
  }

  useRemoveMutation() {
    const add = ToasterProvider.useToasterMessageAdder()
    return useMutation<unknown, unknown, string>((id: string) => this.crudApi.remove(id), {
      onSuccess: () => {
        add({
          message: `${this.entityName} removed`,
          type: 'success',
        })
      },
    })
  }

  useItemQuery(id: string, options?: QueryOptions<ItemResponse>) {
    return useQuery<ItemResponse>([this.entityKey, 'item', id], () => this.crudApi.item(id), options)
  }

  useListQuery(params: ListParams, options?: WithAdditionalQueryKeyPart<QueryOptions<ListResponse>>) {
    return useQuery<ListResponse>(
      [this.entityKey, 'list', params, options?.additionalQueryKeyPart],
      () => this.crudApi.list(params),
      omit(options, 'additionalQueryKeyPart')
    )
  }

  useCountQuery(params: CountParams, options?: WithAdditionalQueryKeyPart<QueryOptions<number>>) {
    return useQuery<number>(
      [this.entityKey, 'count', params, options?.additionalQueryKeyPart],
      () => this.crudApi.count(params),
      omit(options, 'additionalQueryKeyPart')
    )
  }
}
