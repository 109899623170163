import cx from 'classnames'
import React, {ReactNode, useContext, useEffect} from 'react'
import {ReactComponent as CloseIcon} from '../icons/close.svg'
import {Backdrop} from './Backdrop'

type HeaderProps = {
  title?: string
  subtitle?: string
}

type ModalProps = HeaderProps & {
  className?: string
  modalClassName?: string
  bodyClassName?: string
  onClose?: () => void
  children: ReactNode
  fullscreen?: boolean
}

type ModalContextValue = {
  onClose?: () => void
}

type ProviderProps = ModalContextValue & {
  children: ReactNode
}

const ModalContext = React.createContext<ModalContextValue>({
  onClose: undefined,
})

export const ModalProvider = ({children, onClose}: ProviderProps) => {
  const contextValue = {
    onClose,
  }

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>
}

const useModalContext = () => {
  return useContext(ModalContext)
}

const useOnClose = () => {
  const {onClose} = useModalContext()
  return onClose
}

const Header = ({title, subtitle}: HeaderProps) => {
  const onClose = useOnClose()

  return (
    <>
      {title && (
        <div className="flex justify-between items-center p-4 border-b">
          <div className="flex flex-col gap-1">
            <div className="text-sm font-semibold">{title}</div>
            {subtitle && <div className="text-xs font-semibold">{subtitle}</div>}
          </div>
          {onClose && (
            <div onClick={onClose}>
              <CloseIcon className="w-6 h-6 cursor-pointer" />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export const Modal = ({className, modalClassName, bodyClassName, onClose, title, subtitle, children, fullscreen}: ModalProps) => {
  useEffect(() => {
    const tags = document.getElementsByTagName('body')
    if (tags.length === 0) {
      return
    }
    const body = tags[0]
    body.classList.add('overflow-hidden')

    return () => {
      const tags = document.getElementsByTagName('body')
      if (tags.length === 0) {
        return
      }
      const body = tags[0]
      body.classList.remove('overflow-hidden')
    }
  }, [])

  return (
    <Backdrop className={cx('z-10', className)} onClick={onClose}>
      <div
        className={cx('flex flex-col bg-white', fullscreen ? 'w-screen h-screen' : 'rounded-lg', modalClassName)}
        onClick={(event) => event.stopPropagation()}
      >
        <ModalProvider onClose={onClose}>
          <Header title={title} subtitle={subtitle} />
          <div className={cx('p-4', bodyClassName)}>{children}</div>
        </ModalProvider>
      </div>
    </Backdrop>
  )
}

Modal.useOnClose = useOnClose

Modal.Header = Header
