import {UserProvider} from 'providers/UserProvider'

type Props = {
  onShowSidebar: () => void
}

export const Navbar = ({onShowSidebar}: Props) => {
  const user = UserProvider.useUser()

  const signOut = () => {
    window.localStorage.removeItem('token')
    window.location.href = '/signIn'
  }

  return (
    <nav className="w-full border-gray-200 bg-gray-50">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between p-4">
        <div>
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={onShowSidebar}>
            <span className="sr-only">Open sidebar</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
            </svg>
          </button>
        </div>
        <div className="flex gap-4 items-center">
          <div className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-3 items-center rounded-md text-sm leading-6 font-semibold">
            {user.firstName} {user.lastName}
          </div>
          <div
            className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-3 items-center rounded-md  text-sm leading-6 font-semibold cursor-pointer"
            onClick={signOut}
          >
            Sign out
          </div>
        </div>
      </div>
    </nav>
  )
}
