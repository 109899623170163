import {Params, Responses} from 'common/types/locations'
import {Utils} from 'ui'
import {axiosInstance} from './getInstance'

export const crudApi = new Utils.CrudApi<
  Params.Count,
  Params.List,
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update
>('/locations', axiosInstance)
