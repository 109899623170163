import moment from 'moment'

export const getWeekOptions = (year: number, month: number) => {
  const firstMonthDay = moment({year, month}).startOf('month')

  let mondayStart = moment(firstMonthDay).subtract(firstMonthDay.day() - 1, 'days')

  const options = []

  do {
    const sunday = moment(mondayStart).add(7, 'days')
    const sundayEnd = moment(sunday).subtract(1, 'second')
    options.push({
      label: `${mondayStart.format('MMM D')} - ${sundayEnd.format('MMM D')}`,
      value: `${moment.utc(mondayStart).format()}|${moment.utc(sundayEnd).format()}`,
    })

    mondayStart = sunday
  } while (mondayStart.month() === month)

  return options
}
