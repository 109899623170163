import {useIsLoading} from 'hooks/useIsLoading'
import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter as Router} from 'react-router-dom'
import {Components, Providers} from 'ui'
import {Routing} from './Routing'

const WaitingChildren = () => {
  const isLoading = useIsLoading()
  const isWaiting = Providers.WaitingProvider.useIsWaiting()
  const showSpinner = isLoading || isWaiting

  return (
    <>
      <Router>
        <Routing />
      </Router>
      {showSpinner && <Components.Spinner />}
    </>
  )
}

const ToasterChildren = () => {
  const addToasterMessage = Providers.ToasterProvider.useToasterMessageAdder()

  const queryClientConfig = {
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onError(error: any) {
          addToasterMessage({
            message: error.message || 'Unknown error',
            type: 'danger',
          })
        },
      },
    },
  }

  const queryClient = new QueryClient(queryClientConfig)

  return (
    <QueryClientProvider client={queryClient}>
      <Providers.WaitingProvider>
        <WaitingChildren />
      </Providers.WaitingProvider>
    </QueryClientProvider>
  )
}

export const App = () => {
  return (
    <Providers.ToasterProvider>
      <ToasterChildren />
    </Providers.ToasterProvider>
  )
}
