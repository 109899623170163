import {Params, Responses} from 'common/types/guides'
import {Utils} from 'ui'
import {axiosInstance} from './getInstance'

export class CrudApi extends Utils.CrudApi<Params.Count, Params.List, never, Params.Update, Responses.List, Responses.Item, never, Responses.Update> {
  async allowedForAssignment(params: Params.AllowedForAssignment): Promise<Responses.AllowedForAssignment> {
    const result = await this.axiosInstance.post<Responses.AllowedForAssignment>(this.entityApiPath + '/listOfAllowedForAssignment', params)
    return result.data
  }

  async assignedToShift(params: Params.AssignedToShift): Promise<Responses.AssignedToShift> {
    const result = await this.axiosInstance.post<Responses.AssignedToShift>(this.entityApiPath + '/listOfAssignedToShift', params)
    return result.data
  }
}

export const crudApi = new CrudApi('/guides', axiosInstance)
