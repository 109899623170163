import cx from 'classnames'
import {ComponentType, ReactNode} from 'react'
import {useNavigate} from 'react-router-dom'

type Props = {
  icon: ComponentType<{className?: string}>
  title: ReactNode
  to: string
  className?: string
  collapsed?: boolean
  onClick?: () => void
}

export const SidebarItem = ({icon: Icon, title, to, className, collapsed, onClick}: Props) => {
  const navigate = useNavigate()

  const changeLocation = () => {
    navigate(to)
    onClick && onClick()
  }

  return (
    <div
      onClick={changeLocation}
      className={cx(
        'text-white hover:text-blue-600 hover:bg-gray-50 group flex gap-3 items-center rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer',
        collapsed ? 'w-fit' : '',
        className
      )}
    >
      <Icon className="w-6 h-6" />
      {!collapsed && title}
    </div>
  )
}
