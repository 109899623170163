import {Locations, Products, Shifts} from 'common/types'
import {shiftHooks} from 'hooks/shifts'
import {ReactComponent as Close} from 'icons/Close.svg'
import {Components} from 'ui'
import {formatIntervalAndDuration} from 'utils/formatIntervalAndDuration'
import {ShiftInfo} from '../ShiftInfo'
import {ShiftScheduling} from '../ShiftScheduling'

type Props = {
  shift: Shifts.Basic.AugmentedEntity
  product: Products.Basic.StorableEntity
  location: Locations.Basic.StorableEntity
  onClose: () => void
  onChange: () => void
}

export const ShiftViewer = ({shift, product, location, onClose, ...props}: Props) => {
  const targetShiftQuery = shiftHooks.useItemQuery(shift.id, {
    initialData: shift,
  })

  const onChange = () => {
    props.onChange()
    targetShiftQuery.refetch()
  }

  if (!targetShiftQuery.data) {
    return null
  }

  return (
    <Components.Modal fullscreen onClose={onClose} bodyClassName="!p-0 w-full h-full">
      <div className="flex flex-col w-full h-full text-sm bg-white">
        <div className="m-6 bg-white">
          <div className="flex justify-between">
            <div>
              <div className="font-semibold">{formatIntervalAndDuration(shift.startedAt, shift.endedAt, {showDate: true, showDay: true})}</div>
              <div className="font-semibold text-xs">{targetShiftQuery.data.comment}</div>
            </div>

            <Close className="w-6 h-6 cursor-pointer" onClick={onClose} />
          </div>
        </div>
        <div className="grid grid-cols-[1fr,3fr] gap-6 grow px-6 pb-6 min-h-0">
          <ShiftInfo
            product={product}
            location={location}
            shift={targetShiftQuery.data}
            className="bg-gray-50 border border-gray-200 p-6 rounded-lg min-h-0"
            showGuides
            showStatus
          />
          <ShiftScheduling shift={targetShiftQuery.data} onChange={onChange} />
        </div>
      </div>
    </Components.Modal>
  )
}
