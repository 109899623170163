import {Components, Types} from 'ui'

type Props = {
  options: Types.SelectOptions
  category: string
  onChange: (productId: string) => void
}

export const CategoryFilter = ({options, category, onChange}: Props) => {
  return (
    <div className="flex gap-2 items-center">
      <Components.Label>Category</Components.Label>
      <Components.Select
        options={options}
        value={category}
        size="sm"
        onChange={(event) => onChange(event.target.value)}
        className="!w-56"
        notSelectedOption
      />
    </div>
  )
}
