import cx from 'classnames'
import {Products, Shifts} from 'common/types'
import {formatIntervalAndDuration} from 'utils/formatIntervalAndDuration'

type Props = {
  shift: Shifts.Basic.AugmentedEntity
  product: Products.Basic.Entity
  horizontal?: boolean
  className?: string
}

const getShiftSummary = (shift: Shifts.Basic.AugmentedEntity) => {
  return `${shift.confirmedBookingQty} bookings / ${shift.confirmedAdultQty + shift.confirmedChildQty} guests`
}

export const ShiftCardInfo = ({shift, product, horizontal, className}: Props) => {
  if (horizontal) {
    return (
      <div className={cx('flex justify-between text-[11px] leading-tight', className)}>
        <div className="text-[11px]">
          <div className="font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
            {formatIntervalAndDuration(shift.startedAt, shift.endedAt, {showDate: true, showDay: true})}
          </div>
          <div className="text-ellipsis whitespace-nowrap overflow-hidden">{getShiftSummary(shift)}</div>
        </div>
        <div className="text-ellipsis whitespace-nowrap overflow-hidden">{product.shortName}</div>
      </div>
    )
  }

  return (
    <div className={cx('text-[11px] leading-tight', className)}>
      <div className="font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
        {formatIntervalAndDuration(shift.startedAt, shift.endedAt, {showDate: true, showDay: true})}
      </div>
      <div className="text-ellipsis whitespace-nowrap overflow-hidden">{product.shortName}</div>
      <div className="text-ellipsis whitespace-nowrap overflow-hidden">{getShiftSummary(shift)}</div>
    </div>
  )
}
