import {range} from 'lodash-es'

type Props = {
  total: number
  limit?: number
  offset?: number
  onChangeOffset: (offset: number) => void
}

const DEFAULT_LIMIT = 50

const getTotalPageQty = (total: number, limit: number) => {
  return Math.floor(total / limit)
}

const getPages = (total: number, limit: number, offset: number) => {
  const totalPageQty = getTotalPageQty(total, limit)
  const currentPage = offset / limit + 1
  const beforeCurrentPage = currentPage - 2
  const afterCurrentPage = currentPage + 2

  const pages = []

  if (beforeCurrentPage < 3) {
    pages.push(...range(1, currentPage + 1))
  } else {
    pages.push(1, 2, 3, null, ...range(beforeCurrentPage, currentPage))
  }

  if (afterCurrentPage > totalPageQty - 3) {
    pages.push(...range(currentPage, totalPageQty + 1))
  } else {
    pages.push(...range(currentPage, afterCurrentPage), null, ...range(totalPageQty - 3, totalPageQty + 1))
  }

  return pages
}

export const Paginator = ({total, offset = 0, limit = DEFAULT_LIMIT, onChangeOffset}: Props) => {
  const pages = getPages(total, limit, offset)
  const totalPageQty = getTotalPageQty(total, limit)

  return (
    <div className="flex justify-between text-sm">
      <div>
        Showing {offset + 1} to {Math.min(offset + limit, total)} of {total} entries
      </div>

      {totalPageQty > 1 ? (
        <div className="flex border rounded">
          {pages.map((page, index) => {
            if (page === null) {
              return (
                <div key={page + '.' + index} className="px-3 py-2">
                  ...
                </div>
              )
            }

            return (
              <div key={index} className="px-3 py-2" onClick={() => onChangeOffset(page * offset)}>
                {page}
              </div>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}
