import {Components, Types} from 'ui'

type Props = {
  options: Types.SelectOptions
  locationId: string
  onChange: (locationId: string) => void
}

export const LocationFilter = ({options, locationId, onChange}: Props) => {
  return (
    <div className="flex gap-2 items-center">
      <Components.Label>Location</Components.Label>
      <Components.Select
        options={options}
        value={locationId}
        size="sm"
        onChange={(event) => onChange(event.target.value)}
        className="!w-32"
        notSelectedOption
      />
    </div>
  )
}
