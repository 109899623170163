import {Components} from 'ui'
import {getDayOptions} from 'utils/getDayOptions'

type Props = {
  year: number
  month: number
  day: number
  onChange: (day: number) => void
}

export const DayFilter = ({year, month, day, onChange}: Props) => {
  const dayOptions = getDayOptions(year, month)
  return (
    <div className="flex gap-2 items-center">
      <Components.Select
        options={dayOptions}
        value={day.toString()}
        size="sm"
        onChange={(event) => onChange(parseInt(event.target.value, 10))}
        className="!w-20"
      />
    </div>
  )
}
