import cx from 'classnames'
import {Shifts} from 'common/types'
import {assignmentHooks} from 'hooks/assignments'
import {guideHooks} from 'hooks/guides'
import {difference, map, without} from 'lodash-es'
import {useState} from 'react'
import {AllowedGuidelist} from './AllowedGuideList'
import {AssignedGuidelist} from './AssignedGuideList'

type Props = {
  shift: Shifts.Basic.AugmentedEntity
  onChange: () => void
  className?: string
}

export const ShiftScheduling = ({shift, onChange, className}: Props) => {
  const [addedGuideIds, setAddedGuideIds] = useState<string[]>([])
  const [removedGuideIds, setRemovedGuideIds] = useState<string[]>([])

  const allowedGuideListQuery = guideHooks.useAllowedForAssignmentQuery({shiftId: shift.id})
  const assignedGuideListQuery = guideHooks.useAssignedToShiftQuery({shiftId: shift.id})

  const shiftAssignmentListQuery = assignmentHooks.useListQuery({
    filters: [
      {field: 'shiftId', value: shift.id},
      {field: 'guideId', values: map(assignedGuideListQuery.data, 'id')},
    ]
  }, {
    enabled: Boolean(assignedGuideListQuery.data)
  })

  const bulkChangeMutation = assignmentHooks.useBulkChangeMutation()

  const onRemoveAssignedGuide = (id: string) => {
    setRemovedGuideIds((list) => [...list, id])
  }

  const onRemoveAddedGuide = (id: string) => {
    setAddedGuideIds((list) => without(list, id))
  }

  const onAddRemovedGuide = (id: string) => {
    setRemovedGuideIds((list) => without(list, id))
  }

  const onAddAllowedGuide = (id: string) => {
    setAddedGuideIds((list) => [...list, id])
  }

  const onAutoAssign = (ids: string[]) => {
    setAddedGuideIds((list) => [...list, ...ids.slice(0, shift.requiredGuideQty - shift.assignedGuideQty)])
  }

  const onReset = () => {
    setRemovedGuideIds([])
    setAddedGuideIds([])
  }

  const onSave = () => {
    bulkChangeMutation.mutate(
      {
        shiftId: shift.id,
        addedGuideIds,
        removedGuideIds,
      },
      {
        onSuccess() {
          onChange()
          allowedGuideListQuery.refetch()
          assignedGuideListQuery.refetch()
        },
      }
    )
  }

  const allowedGuideListQueryData = allowedGuideListQuery.data || []
  const assignedGuideListQueryData = assignedGuideListQuery.data || []
  const shiftAssignmentListQueryData = shiftAssignmentListQuery.data || []

  const usedGuides = [...allowedGuideListQueryData, ...assignedGuideListQueryData]

  const suitableGuidesIds = map(allowedGuideListQueryData, 'id')
  const allowedGuideIds = difference(suitableGuidesIds, addedGuideIds)

  const isLoading = !allowedGuideListQuery.data || !assignedGuideListQuery.data || !shiftAssignmentListQuery.data

  const assignedOnTargetShiftGuideIds = map(assignedGuideListQueryData, 'id')

  const unchangedGuideIds = difference(assignedOnTargetShiftGuideIds, removedGuideIds)

  return (
    <div className={cx('grid grid-cols-[1fr_150px_1fr] min-h-0 h-full', className)}>
      <AssignedGuidelist
        guides={usedGuides}
        assignments={shiftAssignmentListQueryData}
        shift={shift}
        addedGuideIds={addedGuideIds}
        removedGuideIds={removedGuideIds}
        unchangedGuideIds={unchangedGuideIds}
        onRemoveAssignedGuide={onRemoveAssignedGuide}
        onRemoveAddedGuide={onRemoveAddedGuide}
        onAddRemovedGuide={onAddRemovedGuide}
        onReset={onReset}
        onSave={onSave}
        className="min-w-0 col-span-2 h-full min-h-0 shrink-0 pr-3"
        isLoading={isLoading}
      />

      <AllowedGuidelist
        guides={allowedGuideListQueryData}
        allowedGuideIds={allowedGuideIds}
        onAddAllowedGuide={onAddAllowedGuide}
        onAutoAssign={onAutoAssign}
        className="min-w-0 h-full min-h-0 shrink-0 pl-3"
        isLoading={isLoading}
      />
    </div>
  )
}
