import {orderBy} from 'lodash-es'
import moment from 'moment'

export type IntervalInfo = {
  startDateTime: string
  endDateTime: string
  entity: string
  entityId?: string
  startOverflow?: boolean
  endOverflow?: boolean
}

export type ExtendedIntervalInfo = IntervalInfo & {
  span: number
  key: string
}

export type IntervalLike = {
  startedAt: string
  endedAt: string
  entity: string
  entityId: string
}

const MINS_15 = 1000 * 60 * 15

const extendIntervalInfo = (intervalInfo: IntervalInfo): ExtendedIntervalInfo => {
  return {
    ...intervalInfo,
    span: moment(intervalInfo.endDateTime).diff(intervalInfo.startDateTime) / MINS_15,
    key: `${intervalInfo.startDateTime}|${intervalInfo.startDateTime}|${intervalInfo.entity}|${intervalInfo.entityId}`,
  }
}

export const getIntervals = (startOfWeek: string, endOfWeek: string, items: IntervalLike[]): ExtendedIntervalInfo[] => {
  // console.log({startOfWeek, endOfWeek, shifts})
  const orderedItems = orderBy(items, 'startDateTime')

  if (orderedItems.length === 0) {
    return [
      extendIntervalInfo({
        startDateTime: startOfWeek,
        endDateTime: endOfWeek,
        entity: 'empty',
      }),
    ]
  }

  const result = []

  if (orderedItems.length === 1) {
    const singleItem = orderedItems[0]

    if (singleItem.startedAt > startOfWeek) {
      result.push(
        extendIntervalInfo({
          startDateTime: startOfWeek,
          endDateTime: singleItem.startedAt,
          entity: 'empty',
        })
      )
    }

    result.push(
      extendIntervalInfo({
        startDateTime: singleItem.startedAt < startOfWeek ? startOfWeek : singleItem.startedAt,
        endDateTime: singleItem.endedAt > endOfWeek ? endOfWeek : singleItem.endedAt,
        entity: singleItem.entity,
        entityId: singleItem.entityId,
        startOverflow: singleItem.startedAt < startOfWeek,
        endOverflow: singleItem.endedAt > endOfWeek,
      })
    )

    if (singleItem.endedAt < endOfWeek) {
      result.push(
        extendIntervalInfo({
          startDateTime: singleItem.endedAt,
          endDateTime: endOfWeek,
          entity: 'empty',
        })
      )
    }

    // console.log('intervals', result)
    return result.filter((item) => item.span !== 0)
  }

  const firstItem = orderedItems[0]
  const prenultItem = orderedItems[orderedItems.length - 2]
  const lastItem = orderedItems[orderedItems.length - 1]

  if (firstItem.startedAt < startOfWeek) {
    result.push(
      extendIntervalInfo({
        startDateTime: startOfWeek,
        endDateTime: firstItem.endedAt,
        entity: firstItem.entity,
        entityId: firstItem.entityId,
        startOverflow: true,
      })
    )
  } else {
    result.push(
      extendIntervalInfo({
        startDateTime: startOfWeek,
        endDateTime: firstItem.startedAt,
        entity: 'empty',
      })
    )
    result.push(
      extendIntervalInfo({
        startDateTime: firstItem.startedAt,
        endDateTime: firstItem.endedAt,
        entity: firstItem.entity,
        entityId: firstItem.entityId,
      })
    )
  }

  for (let i = 1; i < orderedItems.length - 1; i++) {
    const currentItem = orderedItems[i]
    const previousItem = orderedItems[i - 1]

    if (currentItem.startedAt === previousItem.endedAt) {
      result.push(
        extendIntervalInfo({
          startDateTime: currentItem.startedAt,
          endDateTime: currentItem.endedAt,
          entity: currentItem.entity,
          entityId: currentItem.entityId,
        })
      )
    } else {
      result.push(
        extendIntervalInfo({
          startDateTime: previousItem.endedAt,
          endDateTime: currentItem.startedAt,
          entity: 'empty',
        })
      )
      result.push(
        extendIntervalInfo({
          startDateTime: currentItem.startedAt,
          endDateTime: currentItem.endedAt,
          entity: currentItem.entity,
          entityId: currentItem.entityId,
        })
      )
    }
  }

  if (items.length >= 2 && lastItem.startedAt !== prenultItem.endedAt) {
    result.push(
      extendIntervalInfo({
        startDateTime: prenultItem.endedAt,
        endDateTime: lastItem.startedAt,
        entity: 'empty',
      })
    )
  }

  if (lastItem.endedAt > endOfWeek) {
    result.push(
      extendIntervalInfo({
        startDateTime: lastItem.startedAt,
        endDateTime: endOfWeek,
        entity: lastItem.entity,
        entityId: lastItem.entityId,
        endOverflow: true,
      })
    )
  } else {
    result.push(
      extendIntervalInfo({
        startDateTime: lastItem.startedAt,
        endDateTime: lastItem.endedAt,
        entity: lastItem.entity,
        entityId: lastItem.entityId,
      })
    )
    result.push(
      extendIntervalInfo({
        startDateTime: lastItem.endedAt,
        endDateTime: endOfWeek,
        entity: 'empty',
      })
    )
  }

  // console.log('intervals', result)
  return result.filter((item) => item.span !== 0)
}
