import {Locations, Products} from 'common/types'
import {MonthCalendar} from 'components/calendars'
import {MonthViewFilters, getDefaultMonthViewFilters} from 'components/dashboard/MonthViewFilters'
import {OperatorGroupFilters, OperatorGroupFiltersValue} from 'components/dashboard/OperatorGroupFilters'
import {View, ViewSwitcher} from 'components/dashboard/ViewSwitcher'
import {shiftHooks} from 'hooks/shifts'
import {isEmpty} from 'lodash-es'
import moment from 'moment'
import {useState} from 'react'

type Props = {
  locations?: Locations.Basic.StorableEntity[]
  products?: Products.Basic.StorableEntity[]
  onChangeView: (view: View) => void
  groupFilters: OperatorGroupFiltersValue
  onChangeGroupFilters: (filters: OperatorGroupFiltersValue) => void
}

export const MonthView = ({locations, products, onChangeView, groupFilters, onChangeGroupFilters}: Props) => {
  const [intervalFilters, setIntervalFilters] = useState(getDefaultMonthViewFilters())
  const [calendarIntervalFilters, setCalendarIntervalFilters] = useState(intervalFilters)

  const startedAt = moment.utc(moment({...intervalFilters, day: 1})).format()
  const endedAt = moment.utc(startedAt).add(1, 'month').subtract(1, 'second').format()

  const shiftListQuery = shiftHooks.useListQuery(
    {
      filters: [
        {field: 'startedAt', op: 'between', start: startedAt, end: endedAt},
        {field: 'areThereConfirmedBookings', value: true},
        {field: 'productId', value: groupFilters.productId, skip: isEmpty(groupFilters.productId)},
        {field: 'locationId', value: groupFilters.locationId, skip: isEmpty(groupFilters.locationId)},
        {field: 'isAssignmentCompleted', value: false, skip: groupFilters.category !== 'uncompleted_assignment'},
        {field: 'areThereExtraAssignments', value: true, skip: groupFilters.category !== 'extra_assignments'},
        {field: 'areTherePendingAssignments', value: true, skip: groupFilters.category !== 'pending_assignments'},
      ],
      ordering: ['startedAt'],
    },
    {
      keepPreviousData: true,
      additionalQueryKeyPart: 'month',
      onSuccess: () => setCalendarIntervalFilters(intervalFilters),
    }
  )

  if (!locations || !products || !shiftListQuery.data) {
    return null
  }

  const filteredShifts = shiftListQuery.data

  const onChange = () => {
    shiftListQuery.refetch()
  }

  return (
    <div className="flex flex-col">
      <div className="flex p-4">
        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-5 items-center">
            <ViewSwitcher view="month" onChange={onChangeView} />
            <MonthViewFilters value={intervalFilters} onChange={setIntervalFilters} />
          </div>
          <div className="flex justify-between w-full">
            <OperatorGroupFilters locations={locations} products={products} value={groupFilters} onChange={onChangeGroupFilters} />
          </div>
        </div>
      </div>
      <MonthCalendar
        year={calendarIntervalFilters.year}
        month={calendarIntervalFilters.month}
        shifts={filteredShifts}
        products={products}
        locations={locations}
        onChange={onChange}
      />
    </div>
  )
}
