import {Navbar} from 'components/Navbar'
import {DesktopSidebar} from 'components/sidebar/DesktopSidebar'
import {MobileSidebar} from 'components/sidebar/MobileSidebar'
import {SignIn} from 'pages/SignIn'
import * as Guides from 'pages/guides'
import * as Locations from 'pages/locations'
import * as Payouts from 'pages/payouts'
import * as Products from 'pages/products'
import * as Shifts from 'pages/shifts'
import * as Users from 'pages/users'
import {UserProvider} from 'providers/UserProvider'
import {useState} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'

const Dashboard = () => {
  const [sidebarVisibility, setSidebarVisibility] = useState(false)
  return (
    <div className="flex w-full min-h-screen">
      <MobileSidebar visible={sidebarVisibility} onClose={() => setSidebarVisibility(false)} />
      <DesktopSidebar className="" />
      <div className="flex flex-col grow w-full">
        <Navbar onShowSidebar={() => setSidebarVisibility(true)} />
        <div className="w-full">
          <div className="w-[1024px] m-auto p-4">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

const FullScreenDashboard = () => {
  const [sidebarVisibility, setSidebarVisibility] = useState(false)
  return (
    <div className="flex w-full min-h-screen">
      <MobileSidebar visible={sidebarVisibility} onClose={() => setSidebarVisibility(false)} />
      <DesktopSidebar className="" />
      <div className="flex flex-col grow w-full">
        <Navbar onShowSidebar={() => setSidebarVisibility(true)} />
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FullScreenDashboard />}>
        <Route path="shifts/scheduling" element={<Shifts.Scheduling />} />
        <Route path="shifts/assigned" element={<Shifts.Assigned />} />
        <Route path="shifts/available" element={<Shifts.Available />} />
        <Route path="payouts" element={<Payouts.View />} />
        <Route path="" element={<Navigate to="/shifts/scheduling" replace={true} />} />
      </Route>
      <Route path="/" element={<Dashboard />}>
        <Route path="locations" element={<Locations.List />} />
        <Route path="locations/create" element={<Locations.Create />} />
        <Route path="locations/:id/edit" element={<Locations.Edit />} />

        <Route path="products" element={<Products.List />} />
        <Route path="products/create" element={<Products.Create />} />
        <Route path="products/:id/edit" element={<Products.Edit />} />

        <Route path="users" element={<Users.List />} />
        <Route path="users/create" element={<Users.Create />} />
        <Route path="users/:id/edit" element={<Users.Edit />} />

        <Route path="guides" element={<Guides.List />} />
        <Route path="guides/:id/edit" element={<Guides.Edit />} />
      </Route>
    </Routes>
  )
}

const OperatorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FullScreenDashboard />}>
        <Route path="shifts/scheduling" element={<Shifts.Scheduling />} />
        <Route path="shifts/assigned" element={<Shifts.Assigned />} />
        <Route path="" element={<Navigate to="/shifts/scheduling" replace={true} />} />
      </Route>
    </Routes>
  )
}

const GuideRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FullScreenDashboard />}>
        <Route path="/shifts/available" element={<Shifts.Available />} />
        <Route path="/shifts/assigned" element={<Shifts.Assigned />} />
        <Route path="" element={<Navigate to="/shifts/assigned" replace={true} />} />
      </Route>
    </Routes>
  )
}

const AppRoutes = () => {
  const user = UserProvider.useUser()

  if (user.roles.includes('superAdmin')) {
    return <AdminRoutes />
  }

  if (user.roles.includes('operationsUser')) {
    return <OperatorRoutes />
  }

  if (user.roles.includes('guideUser')) {
    return <GuideRoutes />
  }

  return null
}

export const Routing = () => {
  return (
    <Routes>
      <Route path="/signIn" element={<SignIn />} />
      <Route
        path="/*"
        element={
          <UserProvider>
            <AppRoutes />
          </UserProvider>
        }
      />
    </Routes>
  )
}
