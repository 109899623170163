import {locationHooks} from 'hooks/locations'
import {useNavigate} from 'react-router-dom'
import {Components} from 'ui'

export const List = () => {
  const navigate = useNavigate()

  const paginatedQuery = locationHooks.useListQuery({
    limit: 50,
    ordering: ['createdAt'],
    filters: [{field: 'isDeleted', value: false}],
  })

  const onAddButtonClick = () => {
    navigate('/locations/create')
  }

  const headers = [
    {key: 'name', value: 'Name'},
    {key: 'shortName', value: 'Short name'},
    {key: 'code', value: 'Code'},
    {key: 'timeZone', value: 'Time zone'},
    {key: 'edit'},
  ]

  if (!paginatedQuery.data) {
    return null
  }

  const rows = paginatedQuery.data.map((location) => ({
    key: location.id,
    columns: {
      name: location.name,
      shortName: location.shortName,
      code: location.code,
      timeZone: location.timeZone,
      edit: <Components.EditLink to={`/locations/${location.id}/edit`} />,
    },
  }))

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Components.Header.H5>Locations</Components.Header.H5>
        <Components.Button onClick={onAddButtonClick}>Add</Components.Button>
      </div>

      <Components.Table headers={headers} rows={rows} />
    </div>
  )
}
