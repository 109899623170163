import {App} from 'App'
import ReactDOM from 'react-dom/client'
import './index.css'

const start = async () => {
  const rootElement = document.getElementById('root')

  if (!rootElement) {
    throw new Error("Can't find a root element")
  }

  const root = ReactDOM.createRoot(rootElement)

  root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
  )
}

start().catch(console.error)
