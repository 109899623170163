import * as Unavailabilities from 'common/types/unavailabilities'
import {Storable} from 'common/types/utils'
import {formatIntervalAndDuration} from 'utils/formatIntervalAndDuration'
import {VerticalInterval} from './VerticalInterval'

type Props = {
  unavailability: Storable<Unavailabilities.Basic.Entity>
  onClick?: () => void
  startCut?: boolean
  endCut?: boolean
  focus?: boolean
  width: number
}

export const UnavailabilityInterval = ({unavailability, endCut, startCut, onClick, focus, width}: Props) => {
  return (
    <VerticalInterval
      borderColor="gray"
      foregroundColor="rgb(209 213 219)"
      backgroundColor="white"
      borderStyle="solid"
      startCut={startCut}
      endCut={endCut}
      onClick={onClick}
      focus={focus}
      width={width}
    >
      <div className="flex flex-col w-full p-0.5 leading-[1.35] min-w-0 text-[11px]">
        <div className="flex justify-between">
          <div className="font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
            {formatIntervalAndDuration(unavailability.startedAt, unavailability.endedAt)}
          </div>
          <div className="text-ellipsis whitespace-nowrap overflow-hidden">Unavailability</div>
        </div>
        <div className="text-ellipsis whitespace-nowrap overflow-hidden">{unavailability.comment ? unavailability.comment : 'No comment'}</div>
      </div>
    </VerticalInterval>
  )
}
