import cx from 'classnames'
import {useState} from 'react'
import {SidebarHeader} from './SidebarHeader'
import {SidebarItems} from './SidebarItems'

type Props = {
  className?: string
}

export const DesktopSidebar = ({className}: Props) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <div className={cx('shrink-0 hidden lg:flex flex-col border-r gap-10 py-3 px-3 bg-blue-600 text-white', collapsed ? 'w-16' : 'w-64', className)}>
      <SidebarHeader onCollapseToggle={() => setCollapsed((prev) => !prev)} collapsed={collapsed} />
      <SidebarItems collapsed={collapsed} />
    </div>
  )
}
