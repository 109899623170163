import {Assignments, Shifts} from 'common/types'
import {Storable} from 'common/types/utils'
import {assignmentHooks} from 'hooks/assignments'
import moment from 'moment'
import {Components} from 'ui'

type Props = {
  shift: Storable<Shifts.Basic.Entity>
  shiftAssignment: Storable<Assignments.Basic.Entity> | null | undefined
  onChangeStatus: () => void
  onUnassign: () => void
}

export const GuideShiftActions = ({shift, shiftAssignment, onChangeStatus, onUnassign}: Props) => {
  const shiftAssignmentUpdateMutation = assignmentHooks.useUpdateMutation()
  const shiftAssignmentRemoveMutation = assignmentHooks.useRemoveMutation()
  const shiftAssignmentShiftAssignMutation = assignmentHooks.useSelfAssignMutation()

  const onAccept = () => {
    if (!shiftAssignment) {
      throw new Error('Shift assignment is not defined')
    }

    shiftAssignmentUpdateMutation.mutate(
      {
        id: shiftAssignment.id,
        status: 'accepted',
      },
      {
        onSuccess: () => onChangeStatus(),
      }
    )
  }

  const onDecline = () => {
    if (!shiftAssignment) {
      throw new Error('Shift assignment is not defined')
    }

    shiftAssignmentRemoveMutation.mutate(shiftAssignment.id, {
      onSuccess: () => onUnassign(),
    })
  }

  const onAssign = () => {
    shiftAssignmentShiftAssignMutation.mutate(
      {
        shiftId: shift.id,
      },
      {
        onSuccess: () => onChangeStatus(),
      }
    )
  }

  const onCloseSwap = () => {
    if (!shiftAssignment) {
      throw new Error('Shift assignment is not defined')
    }

    shiftAssignmentUpdateMutation.mutate(
      {
        id: shiftAssignment.id,
        status: 'accepted',
      },
      {
        onSuccess: () => onChangeStatus(),
      }
    )
  }

  const onSwap = () => {
    if (!shiftAssignment) {
      throw new Error('Shift assignment is not defined')
    }

    shiftAssignmentUpdateMutation.mutate(
      {
        id: shiftAssignment.id,
        status: 'swapping',
      },
      {
        onSuccess: () => onChangeStatus(),
      }
    )
  }

  if (!shiftAssignment) {
    return (
      <Components.Button color="green" size="xs" expanded onClick={onAssign}>
        Assign myself
      </Components.Button>
    )
  }

  if (shiftAssignment.status === 'pending') {
    return (
      <div className="flex flex-col gap-2">
        <Components.Button color="green" size="xs" expanded onClick={onAccept}>
          Accept
        </Components.Button>
        <Components.Button color="red" size="xs" expanded onClick={onDecline}>
          Decline
        </Components.Button>
      </div>
    )
  }

  if (shiftAssignment.status === 'swapping') {
    return (
      <Components.Button color="green" size="xs" expanded onClick={onCloseSwap}>
        Close swap
      </Components.Button>
    )
  }

  if (shiftAssignment.status !== 'accepted') {
    throw new Error(`Unexpected status '${shiftAssignment.status}'`)
  }

  // if (moment.utc(shift.startDateTime).subtract(48, 'hours').isSameOrBefore(moment())) {
  //   return (
  //     <Components.Button size="xs" color="red" expanded onClick={onSwap}>
  //       Swap
  //     </Components.Button>
  //   )
  // }

  if (shiftAssignment.assignerId === shiftAssignment.guideId) {
    return (
      <Components.Button size="xs" color="red" expanded onClick={onDecline}>
        Unassign
      </Components.Button>
    )
  }

  return (
    <Components.Button size="xs" color="red" expanded onClick={onDecline}>
      Decline
    </Components.Button>
  )
}
