import cx from 'classnames'
import {CSSProperties, ReactNode} from 'react'

type Prop = {
  children: ReactNode
  className?: string
  style?: CSSProperties
}

export const Pill = ({children, className, style}: Prop) => {
  return (
    <div
      className={cx('rounded-full text-[11px] leading-none font-semibold text-white w-fit px-2 py-1 h-fit text-center whitespace-nowrap', className)}
      style={style}
    >
      {children}
    </div>
  )
}
