import cx from 'classnames'
import moment from 'moment'
import {useEffect} from 'react'
import {Types} from 'ui'
import {getWeekOptions} from 'utils/getWeekOptions'
import {MonthFilter} from './MonthFilter'
import {WeekFilter} from './WeekFilter'
import {YearFilter} from './YearFilter'

type WeekViewFiltersValue = {
  year: number
  month: number
  week: string
}

type Props = {
  value: WeekViewFiltersValue
  onChange: (value: WeekViewFiltersValue) => void
  showShortMonthNames?: boolean
  className?: string
}

export const getCurrentWeekValue = (weekOptions: Types.SelectOption[]) => {
  const now = moment()

  for (const option of weekOptions) {
    const [from, to] = option.value.split('|')
    if (now.isBetween(moment.utc(from), moment.utc(to))) {
      return option.value
    }
  }

  return weekOptions[0].value
}

export const getDefaultWeekViewFilters = (): WeekViewFiltersValue => {
  const now = moment()
  const year = now.year()
  const month = now.month()
  const weekOptions = getWeekOptions(year, month)
  const week = getCurrentWeekValue(weekOptions)

  return {year, month, week}
}

export const WeekViewFilters = ({value, onChange, showShortMonthNames, className}: Props) => {
  useEffect(() => {
    const weekOptions = getWeekOptions(value.year, value.month)
    onChange({...value, week: getCurrentWeekValue(weekOptions)})
  }, [value.year, value.month])

  return (
    <div className={cx('flex gap-2 items-center flex-wrap', className)}>
      <YearFilter year={value.year} onChange={(year) => onChange({...value, year})} />
      <MonthFilter month={value.month} onChange={(month) => onChange({...value, month})} showShortMonthNames={showShortMonthNames} />
      <WeekFilter year={value.year} month={value.month} week={value.week} onChange={(week) => onChange({...value, week})} />
    </div>
  )
}
