import {Components} from 'ui'
import {getWeekOptions} from 'utils/getWeekOptions'

type Props = {
  year: number
  month: number
  week: string
  onChange: (week: string) => void
}

export const WeekFilter = ({year, month, week, onChange}: Props) => {
  const weekOptions = getWeekOptions(year, month)
  return (
    <div className="flex gap-2 items-center">
      <Components.Select options={weekOptions} value={week} size="sm" onChange={(event) => onChange(event.target.value)} className="!w-[150px]" />
    </div>
  )
}
