import {MonthViewFilters, getDefaultMonthViewFilters} from 'components/dashboard/MonthViewFilters'
import {locationHooks} from 'hooks/locations'
import {useState} from 'react'
import {Components} from 'ui'

export const View = () => {
  const [intervalFilters, setIntervalFilters] = useState(getDefaultMonthViewFilters())

  const locationListQuery = locationHooks.useListQuery({ordering: ['name']})

  if (!locationListQuery.data) {
    return null
  }

  const locationOptions = locationListQuery.data.map((location) => {
    return {
      label: location.name,
      value: location.id,
    }
  })

  return (
    <div className="flex flex-col p-4">
      <div className="flex gap-2 items-center">
        <Components.Label>Month</Components.Label>
        <MonthViewFilters value={intervalFilters} onChange={setIntervalFilters} />
      </div>
    </div>
  )
}
