import {unavailabilityApi} from 'api'
import {Params, Responses} from 'common/types/unavailabilities'
import {Utils} from 'ui'

export const unavailabilityHooks = new Utils.CrudHooks<
  Params.Count,
  Params.List,
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update
>('unavailability', 'Unavailability', unavailabilityApi)
