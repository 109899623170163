import cx from 'classnames'
import {Guides, Shifts, Assignments} from 'common/types'
import {ReactComponent as AddCircle} from 'icons/AddCircle.svg'
import {ReactComponent as DoNotDisturbOn} from 'icons/DoNotDisturbOn.svg'
import {ReactComponent as Done} from 'icons/Done.svg'
import {ReactComponent as Redo} from 'icons/Redo.svg'
import {keyBy} from 'lodash-es'
import {ReactNode} from 'react'
import {Components} from 'ui'
import {AssignmentStatus} from './AssignmentStatus'

type Props = {
  guides: Guides.Basic.StorableSortableEntity[]
  shift: Shifts.Basic.AugmentedEntity
  assignments: Assignments.Basic.Entity[]
  className?: string
  unchangedGuideIds: string[]
  removedGuideIds: string[]
  addedGuideIds: string[]
  onRemoveAssignedGuide: (id: string) => void
  onRemoveAddedGuide: (id: string) => void
  onAddRemovedGuide: (id: string) => void
  onReset: () => void
  onSave: () => void
  isLoading: boolean
}

export const AssignedGuidelist = ({
  guides,
  shift,
  assignments,
  className,
  unchangedGuideIds = [],
  removedGuideIds = [],
  addedGuideIds = [],
  onRemoveAssignedGuide,
  onRemoveAddedGuide,
  onAddRemovedGuide,
  onReset,
  onSave,
  isLoading,
}: Props) => {
  const guideMap = keyBy(guides, 'id')
  const assignmentMap = keyBy(assignments, 'guideId')

  const headers = [
    {key: 'name', value: 'Name'},
    {key: 'assignmentNumber', value: <>Qty of<br/>assignments</>},
    {key: 'guideStatus', value: <>Performance<br/>status</>},
    {key: 'status', value: 'Status'},
    {key: 'control', value: ''},
  ]

  const resultGuides = [
    ...addedGuideIds.map((id) => ({...guideMap[id], state: 'added' as const})),
    ...removedGuideIds.map((id) => ({...guideMap[id], state: 'removed' as const})),
    ...unchangedGuideIds.map((id) => ({...guideMap[id], state: 'unchanged' as const})),
  ]

  const rows = isLoading
    ? []
    : resultGuides.map((guide) => {
        const getControl = (state: 'added' | 'removed' | 'unchanged') => {
          switch (state) {
            case 'added':
              return <DoNotDisturbOn className="w-6 h-6 cursor-pointer" onClick={() => onRemoveAddedGuide(guide.id)} />
            case 'removed':
              return <AddCircle className="w-6 h-6 cursor-pointer" onClick={() => onAddRemovedGuide(guide.id)} />
            case 'unchanged':
              return <DoNotDisturbOn className="w-6 h-6 cursor-pointer" onClick={() => onRemoveAssignedGuide(guide.id)} />
          }
        }

        return {
          key: `${guide.id}|${guide.state}`,
          columns: {
            name: (
              <span className="whitespace-nowrap">
                {guide.lastName} {guide.firstName.slice(0, 1)}.<span className="hidden">{guide.id}</span>
              </span>
            ),
            assignmentNumber: guide.assignmentNumber,
            guideStatus: guide.status,
            control: getControl(guide.state),
            state: guide.state,
            status: <AssignmentStatus assignment={assignmentMap[guide.id]} showNothingIfNotDefined />,
          },
        }
      })

  return (
    <div className={cx('flex flex-col', className)}>
      <div className="py-3 px-3 rounded-t-lg font-semibold bg-gray-50 border  flex justify-between items-center">
        Assigned guides ({unchangedGuideIds.length + addedGuideIds.length} / {shift.requiredGuideQty})
        <div className="flex gap-4">
          <Components.Button
            size="xs"
            color="red"
            className="flex gap-2 items-center"
            onClick={onReset}
            disabled={addedGuideIds.length + removedGuideIds.length === 0}
          >
            <Redo className="w-4 h-4" />
            Reset
          </Components.Button>
          <Components.Button
            size="xs"
            color="green"
            className="flex gap-2 items-center"
            onClick={onSave}
            disabled={addedGuideIds.length + removedGuideIds.length === 0}
          >
            <Done className="w-4 h-4" />
            Save
          </Components.Button>
        </div>
      </div>
      {isLoading && (
        <Components.Table
          headers={[{key: 'loading', value: 'Loading...'}]}
          rows={[]}
          headTrClassName="sticky top-0 bg-slate-500 text-white h-[56px]"
        />
      )}
      {!isLoading && (
        <Components.Table
          headers={headers}
          rows={rows}
          headTrClassName="sticky top-0 bg-slate-500 text-white"
          headThClassName="!px-2"
          getBodyTr={(row, rows, headers) => {
            return (
              <tr
                className={cx('bg-gray-200 border-y-4 border-white', {
                  'bg-gray-200': row.columns.state === 'unchanged',
                  'bg-red-200': row.columns.state === 'removed',
                  'bg-green-200': row.columns.state === 'added',
                })}
              >
                <th scope="row" className={cx('px-2 py-4 font-medium text-gray-900 whitespace-nowrap')}>
                  {row.columns[headers[0].key] as ReactNode}
                </th>
                {headers.slice(1).map((header) => (
                  <td key={header.key} className={cx('px-2 py-4')}>
                    {row.columns[header.key] as ReactNode}
                  </td>
                ))}
              </tr>
            )
          }}
        />
      )}
    </div>
  )
}
