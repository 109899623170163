import {Assignments, Products, Shifts} from 'common/types'
import {Storable} from 'common/types/utils'
import {AssignmentStatus} from 'components/AssignmentStatus'
import {ShiftCardInfo} from 'components/ShiftCardInfo'
import {ShiftFrame} from 'components/shift/ShiftFrame'
import {isShiftOutDated} from 'utils/isShiftOutdated'

type Props = {
  shift: Storable<Shifts.Basic.AugmentedEntity>
  shiftAssignment: Storable<Assignments.Basic.Entity> | null
  product: Storable<Products.Basic.Entity>
  focus: boolean
  onClick: () => void
}

export const ShiftCard = ({shift, shiftAssignment, product, onClick, focus}: Props) => {
  return (
    <ShiftFrame
      borderColor={product.color}
      orientation="horizontal"
      backgroundColor="white"
      foregroundColor={isShiftOutDated(shift) ? 'white' : '#e5e7eb'}
      borderStyle={shift.status === 'planned' ? 'dashed' : 'solid'}
      onClick={onClick}
      focus={focus}
      fixedSideSize={54}
    >
      <div className="flex justify-between w-full h-full p-1">
        <ShiftCardInfo shift={shift} product={product} />
        <AssignmentStatus assignment={shiftAssignment} />
      </div>
    </ShiftFrame>
  )
}
