import cx from 'classnames'

type Props = {
  className?: string
}

export const FocusMark = ({className}: Props) => {
  return (
    <div className={cx('h-full', className)}>
      <div className="min-w-1 w-1 h-full bg-blue-500" />
    </div>
  )
}
