import cx from 'classnames'
import {SidebarHeader} from './SidebarHeader'
import {SidebarItems} from './SidebarItems'

type Props = {
  visible: boolean
  onClose: () => void
  className?: string
}

export const MobileSidebar = ({visible, onClose, className}: Props) => {
  if (!visible) {
    return null
  }

  return (
    <div
      className={cx(
        'fixed z-10 top-0 left-0 h-screen w-screen shrink-0 lg:hidden flex flex-col border-r gap-10 py-3 px-3 bg-blue-600 text-white',
        className
      )}
    >
      <SidebarHeader onClose={onClose} />
      <SidebarItems onClick={onClose} />
    </div>
  )
}
