import {Assignments, Locations, Products, Shifts} from 'common/types'
import {GuideShiftActions} from 'components/GuideShiftActions'
import {assignmentHooks} from 'hooks/assignments'
import {shiftHooks} from 'hooks/shifts'
import {Components} from 'ui'
import {formatIntervalAndDuration} from 'utils/formatIntervalAndDuration'
import {AssignmentStatus} from '../AssignmentStatus'
import {ShiftInfo} from '../ShiftInfo'

export type Props = {
  shift: Shifts.Basic.AugmentedEntity
  product: Products.Basic.StorableEntity
  location: Locations.Basic.StorableEntity
  onClose: () => void
  onChange: () => void
  shiftAssignment: Assignments.Basic.StorableEntity | null | undefined
}

export const ShiftAssignmentViewer = ({shift, product, location, onClose, shiftAssignment}: Props) => {
  const targetShiftQuery = shiftHooks.useItemQuery(shift.id, {
    initialData: shift,
  })

  const targetShiftAssignmentQuery = assignmentHooks.useItemQuery(shiftAssignment ? shiftAssignment.id : '', {
    enabled: !!shiftAssignment,
  })

  const onChangeStatus = () => {
    targetShiftAssignmentQuery.refetch()
    targetShiftQuery.refetch()
  }

  const onUnassign = () => {
    targetShiftAssignmentQuery.refetch()
    targetShiftQuery.refetch()
    onClose()
  }

  if (!targetShiftQuery.data || (shiftAssignment && !targetShiftAssignmentQuery.data)) {
    return null
  }

  return (
    <Components.Modal
      title={formatIntervalAndDuration(shift.startedAt, shift.endedAt, {showDate: true, showDay: true})}
      subtitle={targetShiftQuery.data.comment}
      onClose={onClose}
      modalClassName="w-screen h-screen rounded-none"
      bodyClassName="grow min-h-0"
    >
      <div className="flex flex-col gap-4 grow min-h-0 h-full">
        <div className="flex flex-col justify-end">
          <div className="flex justify-between items-center">
            <div className="text-gray-500 text-sm ">Assignment</div>
            <AssignmentStatus assignment={targetShiftAssignmentQuery.data} />
          </div>
        </div>
        <GuideShiftActions
          shift={targetShiftQuery.data}
          shiftAssignment={targetShiftAssignmentQuery.data}
          onChangeStatus={onChangeStatus}
          onUnassign={onUnassign}
        />
        <ShiftInfo
          product={product}
          location={location}
          shift={targetShiftQuery.data}
          className="bg-gray-50 border border-gray-200 p-6 rounded-lg min-h-0 h-full"
        />
      </div>
    </Components.Modal>
  )
}
