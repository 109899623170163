import {AddUnavailability} from 'components/AddUnavailability'
import {Switcher} from 'components/Switcher'
import {WeekCalendar} from 'components/calendars/guide/WeekCalendar'
import {WeekViewFilters, getDefaultWeekViewFilters} from 'components/dashboard/WeekViewFilters'
import {ShiftList} from 'components/lists/ShiftList'
import {locationHooks} from 'hooks/locations'
import {productHooks} from 'hooks/products'
import {shiftHooks} from 'hooks/shifts'
import {assignmentHooks} from 'hooks/assignments'
import {unavailabilityHooks} from 'hooks/unavailabilities'
import {map} from 'lodash-es'
import {UserProvider} from 'providers/UserProvider'
import {useState} from 'react'
import {Components} from 'ui'

export const Assigned = () => {
  const [view, setView] = useState<'calendar' | 'list'>('list')

  const user = UserProvider.useUser()

  const [intervalFilters, setIntervalFilters] = useState(getDefaultWeekViewFilters())

  const productListQuery = productHooks.useListQuery({
    ordering: ['name'],
  })

  const locationListQuery = locationHooks.useListQuery({
    ordering: ['name'],
  })

  const [startDateTime, endDateTime] = intervalFilters.week.split('|')

  const unavailabilityListQuery = unavailabilityHooks.useListQuery(
    {
      filters: [
        {field: 'startedAt', value: startDateTime},
        {field: 'endedAt', value: endDateTime},
        {field: 'guideId', value: user.id},
      ],
    },
    {
      keepPreviousData: true,
    }
  )

  const assignmentListQuery = assignmentHooks.useListQuery(
    {
      filters: [
        {field: 'startedAt', value: startDateTime},
        {field: 'endedAt', value: endDateTime},
        {field: 'guideId', value: user.id},
      ],
    },
    {
      keepPreviousData: true,
    }
  )

  const shiftListQuery = shiftHooks.useListQuery(
    {
      filters: [
        {field: 'id', values: map(assignmentListQuery.data, 'shiftId')},
      ],
    },
    {
      enabled: Boolean(assignmentListQuery.data),
      keepPreviousData: true,
    }
  )

  const onChange = () => {
    unavailabilityListQuery.refetch()
    assignmentListQuery.refetch()
  }

  if (!assignmentListQuery.data || !productListQuery.data || !locationListQuery.data || !unavailabilityListQuery.data || !shiftListQuery.data) {
    return null
  }

  return (
    <div className="w-full p-4 flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <Components.Text weight="semibold" className="">
          Assigned shifts
        </Components.Text>
        <Switcher
          leftLabel="List"
          rightLabel="Calendar"
          checked={view === 'calendar'}
          onChange={(event) => setView(event.target.checked ? 'calendar' : 'list')}
        />
      </div>

      <div className="flex flex-col justify-between w-full gap-2">
        <WeekViewFilters value={intervalFilters} onChange={setIntervalFilters} showShortMonthNames className="justify-between" />
      </div>

      {view === 'calendar' && (
        <WeekCalendar
          startOfWeek={startDateTime}
          shifts={shiftListQuery.data}
          assignments={assignmentListQuery.data}
          unavailabilities={unavailabilityListQuery.data}
          products={productListQuery.data}
          locations={locationListQuery.data}
          onChange={onChange}
        />
      )}
      {view === 'list' && (
        <ShiftList
          shifts={shiftListQuery.data}
          shiftAssignments={assignmentListQuery.data}
          products={productListQuery.data}
          locations={locationListQuery.data}
          onChange={onChange}
        />
      )}
      <AddUnavailability onAdd={() => unavailabilityListQuery.refetch()} />
    </div>
  )
}
