import {Locations, Products} from 'common/types'
import {Types} from 'ui'
import {CategoryFilter} from './CategoryFilter'
import {LocationFilter} from './LocationFilter'
import {ProductFilter} from './ProductFilter'

export type OperatorGroupFiltersValue = {
  locationId: string
  productId: string
  category: string
}

type Props = {
  locations: Locations.Basic.StorableEntity[]
  products: Products.Basic.StorableEntity[]
  value: OperatorGroupFiltersValue
  onChange: (value: OperatorGroupFiltersValue) => void
}

const CATEGORY_OPTIONS: Types.SelectOptions = [
  {
    label: (
      <div className="flex gap-2 items-center">
        <span className="rounded-full w-2 h-2 bg-orange-300" />
        Uncompleted assignment
      </div>
    ),
    value: 'uncompleted_assignment',
  },
  {
    label: (
      <div className="flex gap-2 items-center">
        <span className="rounded-full w-2 h-2 bg-pink-400" />
        Pending assignments
      </div>
    ),
    value: 'pending_assignments',
  },
  {
    label: (
      <div className="flex gap-2 items-center">
        <span className="rounded-full w-2 h-2 bg-purple-500" />
        Extra assignments
      </div>
    ),
    value: 'extra_assignments',
  },
]

export const DEFAULT_OPERATOR_GROUP_FILTERS: OperatorGroupFiltersValue = {
  productId: '',
  locationId: '',
  category: '',
}

export const OperatorGroupFilters = ({locations, products, value, onChange}: Props) => {
  const locationOptions = locations.map((location) => {
    return {
      label: location.name,
      value: location.id,
    }
  })

  const productOptions = products
    .filter((product) => {
      return value.locationId === '' || product.locationId === value.locationId
    })
    .map((product) => {
      return {
        label: product.shortName,
        value: product.id,
      }
    })

  return (
    <div className="flex gap-5 items-center">
      <LocationFilter options={locationOptions} locationId={value.locationId} onChange={(locationId) => onChange({...value, locationId})} />
      <ProductFilter options={productOptions} productId={value.locationId} onChange={(productId) => onChange({...value, productId})} />
      <CategoryFilter options={CATEGORY_OPTIONS} category={value.category} onChange={(category) => onChange({...value, category})} />
    </div>
  )
}
