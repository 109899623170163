import cx from 'classnames'
import {ReactNode} from 'react'
import {BorderStyle, CuttedBorder} from './CuttedBorder'
import {FocusMark} from './FocusMark'

type Props = {
  borderColor: string
  backgroundColor: string
  foregroundColor: string
  borderStyle: BorderStyle
  startCut?: boolean
  endCut?: boolean
  children: ReactNode
  onClick?: () => void
  focus?: boolean
  width: number
  outdated?: boolean
}

export const VerticalShiftFrame = ({
  borderColor,
  borderStyle,
  backgroundColor,
  foregroundColor,
  startCut,
  endCut,
  children,
  onClick,
  focus,
  width,
  outdated,
}: Props) => {
  const cuttedBorderWidth = width - 2 * 2
  return (
    <div
      className={cx('cursor-pointer border-x-2 h-full flex flex-col', {
        'border-t-2': !startCut,
        'border-b-2': !endCut,
      })}
      style={{
        width,
        borderColor,
        borderStyle,
        backgroundColor: foregroundColor,
      }}
    >
      {startCut && (
        <CuttedBorder backgroundColor={backgroundColor} borderColor={borderColor} edge="top" style={borderStyle} size={cuttedBorderWidth} />
      )}
      <div
        className={cx('flex', {
          'h-full': !endCut && !startCut,
          'h-[calc(100%-10px)]': endCut || startCut,
          'h-[calc(100%-10px-10px)]': endCut && startCut,
        })}
        onClick={onClick}
      >
        {focus && <FocusMark className="p-0.5" />}
        {children}
      </div>
      {endCut && (
        <CuttedBorder backgroundColor={backgroundColor} borderColor={borderColor} edge="bottom" style={borderStyle} size={cuttedBorderWidth} />
      )}
    </div>
  )
}
