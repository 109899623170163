import { ROLES } from 'common/consts/roles'
import {locationHooks} from 'hooks/locations'
import {userHooks} from 'hooks/users'
import {isEmpty} from 'lodash-es'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {Components, Fields} from 'ui'

export const ROLE_OPTIONS = ROLES.map((role) => {
  return {
    label: role.name,
    value: role.code,
  }
})

const getCloneData = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const rawCloneData = searchParams.get('clone')
  return rawCloneData ? JSON.parse(rawCloneData) : {}
}

export const Create = () => {
  const cloneData = getCloneData()
  const navigate = useNavigate()

  const locationsQuery = locationHooks.useListQuery({ordering: ['name']})

  const createMutation = userHooks.useCreateMutation()

  const onSubmit = (values: any) => {
    createMutation.mutate(
      {...values},
      {
        onSuccess(user) {
          navigate(`/users/${user.id}/edit`)
        },
      }
    )
  }

  if (!locationsQuery.data) {
    return null
  }

  const locationOptions = locationsQuery.data.map((location) => {
    return {
      label: location.name,
      value: location.id,
    }
  })

  return (
    <div className="flex flex-col gap-6">
      <Components.Header.H5>Add user</Components.Header.H5>

      <Form
        initialValues={cloneData}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({handleSubmit, pristine, values}) => {
          const disableSave =
            pristine ||
            isEmpty(values.email) ||
            isEmpty(values.firstName) ||
            isEmpty(values.lastName) ||
            isEmpty(values.locationIds) ||
            isEmpty(values.roles)

          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="First name" name="firstName" expanded spellCheck={false} />
              <Fields.InputField label="Last name" name="lastName" expanded spellCheck={false} />
              <Fields.InputField label="Email" name="email" expanded spellCheck={false} />
              <Fields.MultiSelectField label="Roles" name="roles" options={ROLE_OPTIONS} expanded />
              <Fields.MultiSelectField label="Locations" name="locationIds" options={locationOptions} expanded />

              <Components.Button disabled={disableSave} className="ml-auto">
                Save
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
