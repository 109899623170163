import {Locations, Products} from 'common/types'
import {OperatorShiftCategory} from 'common/types/assignments/params'
import {DayCalendar} from 'components/calendars/operator/DayCalendar'
import {DayViewFilters, getDefaultDayViewFilters} from 'components/dashboard/DayViewFilters'
import {OperatorGroupFilters, OperatorGroupFiltersValue} from 'components/dashboard/OperatorGroupFilters'
import {View, ViewSwitcher} from 'components/dashboard/ViewSwitcher'
import {assignmentHooks} from 'hooks/assignments'
import {shiftHooks} from 'hooks/shifts'
import {ReactComponent as MagicStick} from 'icons/MagicStick.svg'
import {isEmpty} from 'lodash-es'
import moment from 'moment'
import {useMemo, useState} from 'react'
import {Components} from 'ui'

type Props = {
  locations?: Locations.Basic.StorableEntity[]
  products?: Products.Basic.StorableEntity[]
  onChangeView: (view: View) => void
  groupFilters: OperatorGroupFiltersValue
  onChangeGroupFilters: (filters: OperatorGroupFiltersValue) => void
}

export const DayView = ({locations, products, onChangeView, groupFilters, onChangeGroupFilters}: Props) => {
  const [intervalFilters, setIntervalFilters] = useState(getDefaultDayViewFilters())

  const [calendarIntervalFilters, setCalendarIntervalFilters] = useState(intervalFilters)

  const startedAt = moment.utc(moment(intervalFilters)).format()
  const endedAt = moment.utc(startedAt).add(1, 'day').subtract(1, 'second').format()

  const shiftListQuery = shiftHooks.useListQuery(
    {
      filters: [
        {expression: 'duringPeriod', args: {startedAt, endedAt}},
        {field: 'areThereConfirmedBookings', value: true},
        {field: 'productId', value: groupFilters.productId, skip: isEmpty(groupFilters.productId)},
        {field: 'locationId', value: groupFilters.locationId, skip: isEmpty(groupFilters.locationId)},
        {field: 'isAssignmentCompleted', value: false, skip: groupFilters.category !== 'uncompleted_assignment'},
        {field: 'areThereExtraAssignments', value: true, skip: groupFilters.category !== 'extra_assignments'},
        {field: 'areTherePendingAssignments', value: true, skip: groupFilters.category !== 'pending_assignments'},
      ],
      ordering: ['startedAt'],
    },
    {
      keepPreviousData: true,
      additionalQueryKeyPart: 'day',
      onSuccess: () => setCalendarIntervalFilters(intervalFilters),
    }
  )

  const autoAssignMutation = assignmentHooks.useAutoAssignMutation()

  if (!locations || !products || !shiftListQuery.data) {
    return null
  }

  const filteredShifts = shiftListQuery.data

  const onChange = () => {
    shiftListQuery.refetch()
  }

  const onAutoAssign = () => {
    autoAssignMutation.mutate(
      {
        startDateTime: startedAt,
        endDateTime: endedAt,
        productIds: groupFilters.productId ? [groupFilters.productId] : undefined,
        locationIds: groupFilters.locationId ? [groupFilters.locationId] : undefined,
        category: groupFilters.category ? (groupFilters.category as OperatorShiftCategory) : undefined,
      },
      {
        onSuccess() {
          shiftListQuery.refetch()
        },
      }
    )
  }

  return (
    <div className="flex flex-col">
      <div className="flex  p-4">
        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-5 items-center">
            <ViewSwitcher view="day" onChange={onChangeView} />
            <DayViewFilters value={intervalFilters} onChange={setIntervalFilters} />
          </div>
          <div className="flex justify-between w-full">
            <OperatorGroupFilters locations={locations} products={products} value={groupFilters} onChange={onChangeGroupFilters} />
            <Components.Button color="green" size="xs" className="flex flex-nowrap gap-2 items-center" onClick={onAutoAssign}>
              <MagicStick className="w-4 h-4" />
              Auto assign
            </Components.Button>
          </div>
        </div>
      </div>
      <DayCalendar
        year={calendarIntervalFilters.year}
        month={calendarIntervalFilters.month}
        day={calendarIntervalFilters.day}
        shifts={filteredShifts}
        products={products}
        locations={locations}
        onChange={onChange}
      />
    </div>
  )
}
