import {range} from 'lodash-es'

export const WEEK_DAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
export const YEAR_OPTIONS = [2024, 2025, 2026]

export const MONTH_OPTIONS = [
  {label: 'January', value: '0'},
  {label: 'February', value: '1'},
  {label: 'March', value: '2'},
  {label: 'April', value: '3'},
  {label: 'May', value: '4'},
  {label: 'June', value: '5'},
  {label: 'July', value: '6'},
  {label: 'August', value: '7'},
  {label: 'September', value: '8'},
  {label: 'October', value: '9'},
  {label: 'November', value: '10'},
  {label: 'Decempber', value: '11'},
]

export const HOUR_OPTIONS = range(24).map((hour) => {
  return {
    label: String(hour).padStart(2, '0'),
    value: hour.toString(),
  }
})

export const MINUTE_OPTIONS = [
  {label: '00', value: '0'},
  {label: '15', value: '15'},
  {label: '30', value: '30'},
  {label: '45', value: '45'},
]

export const ASSIGNMENT_STATUSES = {
  accepted: 'Accepted',
  pending: 'Pending',
  swapping: 'Swapping',
}
