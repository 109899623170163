import * as Unavailabilities from 'common/types/unavailabilities'
import {unavailabilityHooks} from 'hooks/unavailabilities'
import {Components} from 'ui'
import {formatIntervalAndDuration} from 'utils/formatIntervalAndDuration'

export type Props = {
  unavailability: Unavailabilities.Basic.StorableEntity
  onClose: () => void
  onChange: () => void
}

export const UnavailabilityViewer = ({unavailability, onClose, onChange}: Props) => {
  const unavailabilityRemoveMutation = unavailabilityHooks.useRemoveMutation()

  const onRemove = () => {
    unavailabilityRemoveMutation.mutate(unavailability.id, {
      onSuccess: onChange,
    })
  }

  return (
    <Components.Modal
      modalClassName="!w-96"
      title={formatIntervalAndDuration(unavailability.startedAt, unavailability.endedAt, {
        showDate: true,
        showDay: true,
      })}
      subtitle="Unavailability"
      onClose={onClose}
    >
      <div className="flex flex-col gap-4">
        <div className="text-sm">{unavailability.comment ? unavailability.comment : 'No comment'}</div>
        <Components.Button color="red" size="xs" onClick={onRemove}>
          Remove
        </Components.Button>
      </div>
    </Components.Modal>
  )
}
