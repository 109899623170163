import {Products, Shifts} from 'common/types'
import {Storable} from 'common/types/utils'
import {ShiftCardInfo} from 'components/ShiftCardInfo'
import {isShiftOutDated} from 'utils/isShiftOutdated'
import {Circle} from '../../Circle'
import {HorizontalInterval} from './HorizontalInterval'

type Props = {
  shift: Storable<Shifts.Basic.AugmentedEntity>
  product: Storable<Products.Basic.Entity>
  rightCut?: boolean
  leftCut?: boolean
  onClick?: () => void
  focus?: boolean
}

export const ShiftInterval = ({shift, product, leftCut, rightCut, onClick, focus}: Props) => {
  return (
    <HorizontalInterval
      borderColor={product.color}
      backgroundColor="white"
      foregroundColor={isShiftOutDated(shift) ? 'white' : '#e5e7eb'}
      borderStyle={shift.status === 'planned' ? 'dashed' : 'solid'}
      startCut={leftCut}
      endCut={rightCut}
      focus={focus}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <div className="flex justify-between w-full p-0.5 leading-[1.35] min-w-0 text-[11px]">
        <ShiftCardInfo shift={shift} product={product} className="w-[calc(100%-8px)]" />
        <div className="flex flex-col gap-1">
          {!shift.isAssignmentCompleted && <Circle className="bg-orange-300" />}
          {shift.areThereExtraAssignments && <Circle className="bg-purple-500" />}
          {shift.areTherePendingAssignments && <Circle className="bg-pink-400" />}
        </div>
      </div>
    </HorizontalInterval>
  )
}
