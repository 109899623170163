import {omit, range} from 'lodash-es'
import moment from 'moment'
import {ComponentType} from 'react'
import {ExtendedIntervalInfo, IntervalInfo} from 'utils/getIntervals'

type Props = {
  grouppedIntervals: ExtendedIntervalInfo[][]
  intervalComponent: ComponentType<IntervalInfo>
  minRowNumber?: number
}

export const HorizontalIntervalCalendar = ({minRowNumber, grouppedIntervals, intervalComponent: IntervalComponent}: Props) => {
  const startOfDay = moment().startOf('day')

  const rowNumber = minRowNumber === undefined ? grouppedIntervals.length : Math.max(grouppedIntervals.length, minRowNumber)

  return (
    <div className="w-full">
      <div className="relative w-full">
        <div className="grid grid-cols-[repeat(48,minmax(0,1fr))] border-x border-px border-transparent gap-px">
          {range(0, 24).map((hour) => {
            return (
              <div className="flex items-end col-span-2 relative" key={hour}>
                <div className="absolute w-px h-1/2 bg-gray-300 left-[-1px]"></div>
                <div className="text-xs font-semibold leading-6 text-gray-700 pl-2">{moment(startOfDay).add(hour, 'hours').format('HH:mm')}</div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="relative w-full">
        <div className="grid grid-cols-[repeat(48,minmax(0,1fr))] bg-gray-300 gap-px grow border border-px border-gray-300">
          {range(24 * 2 * rowNumber).map((index) => {
            return <div key={index} className="h-[54px] bg-white" /> // 65 = 16 * 4 + 1
          })}
        </div>
        <div className="absolute top-0 left-0 grid grid-cols-[repeat(96,minmax(0,1fr))] border border-px border-transparent gap-px w-full">
          {grouppedIntervals.map((group) => {
            return group.map((interval) => {
              return (
                <div key={interval.key} style={{gridColumn: `span ${interval.span} / span ${interval.span}`}}>
                  <IntervalComponent {...omit(interval, 'key')} />
                </div>
              )
            })
          })}
        </div>
      </div>
    </div>
  )
}
