import {Field} from 'react-final-form'
import {TextArea, TextAreaProps} from '../components/TextArea'

type Props = Omit<TextAreaProps, 'value' | 'onChange' | 'error' | 'name'> & {
  initialValue?: any
  name: string
}

export const TextAreaField = ({name, initialValue, ...other}: Props) => {
  return (
    <Field
      type="textarea"
      name={name}
      initialValue={initialValue}
      render={(props) => {
        return <TextArea {...props.input} {...other} />
      }}
    />
  )
}
