import {ReactNode} from 'react'
import {BorderStyle} from './CuttedBorder'
import {HorizontalShiftFrame} from './HorizontalShiftFrame'
import {VerticalShiftFrame} from './VerticalShiftFrame'

type Orientation = 'vertical' | 'horizontal'

type Props = {
  borderColor: string
  backgroundColor: string
  foregroundColor: string
  borderStyle: BorderStyle
  startCut?: boolean
  endCut?: boolean
  children: ReactNode
  onClick?: () => void
  focus?: boolean
  orientation: Orientation
  fixedSideSize: number
  outdated?: boolean
}

export const ShiftFrame = ({
  borderColor,
  borderStyle,
  backgroundColor,
  foregroundColor,
  startCut,
  endCut,
  children,
  onClick,
  focus,
  orientation,
  fixedSideSize,
  outdated,
}: Props) => {
  if (orientation === 'horizontal') {
    return (
      <HorizontalShiftFrame
        borderColor={borderColor}
        borderStyle={borderStyle}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        startCut={startCut}
        endCut={endCut}
        onClick={onClick}
        focus={focus}
        height={fixedSideSize}
        outdated={outdated}
      >
        {children}
      </HorizontalShiftFrame>
    )
  }

  return (
    <VerticalShiftFrame
      borderColor={borderColor}
      borderStyle={borderStyle}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      startCut={startCut}
      endCut={endCut}
      onClick={onClick}
      focus={focus}
      width={fixedSideSize}
      outdated={outdated}
    >
      {children}
    </VerticalShiftFrame>
  )
}
