import {locationHooks} from 'hooks/locations'
import {productHooks} from 'hooks/products'
import {useId} from 'hooks/useId'
import {isEmpty} from 'lodash-es'
import {Form} from 'react-final-form'
import {Components, Fields} from 'ui'

export const Edit = () => {
  const id = useId()
  const itemQuery = productHooks.useItemQuery(id)
  const locationsQuery = locationHooks.useListQuery({})

  const updateMutation = productHooks.useUpdateMutation()

  const onSubmit = (values: any) => {
    updateMutation.mutate(
      {...values},
      {
        onSuccess() {
          itemQuery.refetch()
        },
      }
    )
  }

  if (!itemQuery.data || !locationsQuery.data) {
    return null
  }

  const locationOptions = locationsQuery.data.map((location) => {
    return {
      label: location.name,
      value: location.id,
    }
  })

  return (
    <div className="flex flex-col gap-6">
      <Components.Header.H5>{`Product ${itemQuery.data.name}`}</Components.Header.H5>

      <Form
        initialValues={itemQuery.data}
        onSubmit={onSubmit}
        render={({handleSubmit, pristine, values}) => {
          const disableSave = pristine || isEmpty(values.name) || isEmpty(values.locationId) || isEmpty(values.color) || isEmpty(values.ratio)

          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="Name" name="name" expanded />
              <Fields.SelectField label="Location" name="locationId" options={locationOptions} expanded />
              <Fields.InputField label="Code" name="code" expanded disabled />
              <Fields.InputField label="Color" name="color" expanded />
              <Fields.InputField label="Ratio" name="ratio" type="number" expanded />

              <Components.Button disabled={disableSave} className="ml-auto">
                Save
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
