import cx from 'classnames'
import {AccessGuard} from 'components/AccessGuard'
import {ReactComponent as CalendarMonth} from 'icons/CalendarMonth.svg'
import {ReactComponent as CalendarToday} from 'icons/CalendarToday.svg'
import {ReactComponent as EventAvailable} from 'icons/EventAvailable.svg'
import {ReactComponent as LocationOn} from 'icons/LocationOn.svg'
import {ReactComponent as Payments} from 'icons/Payments.svg'
import {ReactComponent as Person} from 'icons/Person.svg'
import {ReactComponent as PersonApron} from 'icons/PersonApron.svg'
import {ReactComponent as Work} from 'icons/Work.svg'
import {SidebarItem} from './SidebarItem'

type Props = {
  collapsed?: boolean
  onClick?: () => void
}

export const SidebarItems = ({collapsed, onClick}: Props) => {
  return (
    <div className={cx('flex flex-col gap-4 grow')}>
      <AccessGuard hasSomeOfPermissions={['locations:view']}>
        <SidebarItem to="/locations" icon={LocationOn} title="Locations" collapsed={collapsed} onClick={onClick} />
      </AccessGuard>
      <AccessGuard hasSomeOfPermissions={['products:view']}>
        <SidebarItem to="/products" icon={Work} title="Products" collapsed={collapsed} onClick={onClick} />
      </AccessGuard>
      <AccessGuard hasSomeOfPermissions={['users:view']}>
        <SidebarItem to="/users" icon={Person} title="Users" collapsed={collapsed} onClick={onClick} />
      </AccessGuard>
      <AccessGuard hasSomeOfPermissions={['guides:view']}>
        <SidebarItem to="/guides" icon={PersonApron} title="Guides" collapsed={collapsed} onClick={onClick} />
      </AccessGuard>
      <AccessGuard hasSomeOfPermissions={['shifts:view']}>
        <SidebarItem to="/shifts/scheduling" icon={CalendarMonth} title="Shift assignment" collapsed={collapsed} onClick={onClick} />
      </AccessGuard>
      <AccessGuard hasSomeOfPermissions={['shifts:view']}>
        <SidebarItem to="/shifts/assigned" icon={EventAvailable} title="Assigned shifts" collapsed={collapsed} onClick={onClick} />
      </AccessGuard>
      <AccessGuard hasSomeOfPermissions={['shifts:view']}>
        <SidebarItem to="/shifts/available" icon={CalendarToday} title="Available shifts" collapsed={collapsed} onClick={onClick} />
      </AccessGuard>
      <AccessGuard hasSomeOfPermissions={['shifts:view']}>
        <SidebarItem to="/payouts" icon={Payments} title="Payouts" collapsed={collapsed} onClick={onClick} />
      </AccessGuard>
    </div>
  )
}
