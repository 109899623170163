import {Locations, Products} from 'common/types'
import {WeekCalendar} from 'components/calendars/operator/WeekCalendar'
import {OperatorGroupFilters, OperatorGroupFiltersValue} from 'components/dashboard/OperatorGroupFilters'
import {View, ViewSwitcher} from 'components/dashboard/ViewSwitcher'
import {WeekViewFilters, getDefaultWeekViewFilters} from 'components/dashboard/WeekViewFilters'
import {shiftHooks} from 'hooks/shifts'
import {isEmpty} from 'lodash-es'
import {useState} from 'react'

type Props = {
  locations?: Locations.Basic.StorableEntity[]
  products?: Products.Basic.StorableEntity[]
  onChangeView: (view: View) => void
  groupFilters: OperatorGroupFiltersValue
  onChangeGroupFilters: (filters: OperatorGroupFiltersValue) => void
}

export const WeekView = ({locations, products, onChangeView, groupFilters, onChangeGroupFilters}: Props) => {
  const [intervalFilters, setIntervalFilters] = useState(getDefaultWeekViewFilters())
  const [calendarIntervalFilters, setCalendarIntervalFilters] = useState(intervalFilters)

  const startedAt = intervalFilters.week.split('|')[0]
  const endedAt = intervalFilters.week.split('|')[1]

  const shiftListQuery = shiftHooks.useListQuery(
    {
      filters: [
        {field: 'startedAt', op: 'between', start: startedAt, end: endedAt},
        {field: 'areThereConfirmedBookings', value: true},
        {field: 'productId', value: groupFilters.productId, skip: isEmpty(groupFilters.productId)},
        {field: 'locationId', value: groupFilters.locationId, skip: isEmpty(groupFilters.locationId)},
        {field: 'isAssignmentCompleted', value: false, skip: groupFilters.category !== 'uncompleted_assignment'},
        {field: 'areThereExtraAssignments', value: true, skip: groupFilters.category !== 'extra_assignments'},
        {field: 'areTherePendingAssignments', value: true, skip: groupFilters.category !== 'pending_assignments'},
      ],
      ordering: ['startedAt'],
    },
    {
      keepPreviousData: true,
      additionalQueryKeyPart: 'week',
      onSuccess: () => setCalendarIntervalFilters(intervalFilters),
    }
  )

  if (!locations || !products || !shiftListQuery.data) {
    return null
  }

  const filteredShifts = shiftListQuery.data

  const onChange = () => {
    shiftListQuery.refetch()
  }

  return (
    <div className="flex flex-col">
      <div className="flex  p-4">
        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-5 items-center">
            <ViewSwitcher view="week" onChange={onChangeView} />
            <WeekViewFilters value={intervalFilters} onChange={setIntervalFilters} />
          </div>
          <div className="flex justify-between w-full">
            <OperatorGroupFilters locations={locations} products={products} value={groupFilters} onChange={onChangeGroupFilters} />
          </div>
        </div>
      </div>
      <WeekCalendar
        year={calendarIntervalFilters.year}
        month={calendarIntervalFilters.month}
        week={calendarIntervalFilters.week}
        shifts={filteredShifts}
        products={products}
        locations={locations}
        onChange={onChange}
      />
    </div>
  )
}
