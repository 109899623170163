export const ROLES = [
  {
    code: 'superAdmin',
    name: 'Super admin',
    permissions: ['+ * : * : *'],
  },
  {
    code: 'operationsAdmin',
    name: 'Operations admin',
    permissions: [
      '+ shifts,locations,products,guides : * : allowedLocations',
    ],
  },
  {
    code: 'operationsUser',
    name: 'Operations user',
    permissions: [
      '+ shifts : open,close,addNotes : allowedLocationsAndNotExpired',
      '+ shifts : view : allowedLocations',
    ],
  },
  {
    code: 'guideUser',
    name: 'Guide user',
    permissions: [
      '+ unavailabilities : * : ownAndNotExpired',
      '+ shifts : accept,reject,offerSwap : assignedAndNotExpired',
      '+ shifts : view : allowedLocations',
    ],
  },
  {
    code: 'finanseUser',
    name: 'Finanse user',
    permissions: ['+ shifts : view,export : allowedLocations'],
  },
]
