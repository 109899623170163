import {guideStatusApi} from 'api'
import {Params, Responses} from 'common/types/guidesStatuses'
import {Utils} from 'ui'

export const guidesStatusesHooks = new Utils.CrudHooks<
  never,
  Params.List,
  Params.Create,
  never,
  Responses.List,
  never,
  Responses.Create,
  never
>('guidesStatuses', 'Guide Status', guideStatusApi)
