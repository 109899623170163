export function isChildOf(child: Node, parent: Node): boolean {
  if (child.parentNode === parent) {
    return true
  }

  if (child.parentNode === null) {
    return false
  }

  return isChildOf(child.parentNode, parent)
}
