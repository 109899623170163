import cx from 'classnames'
import {Locations, Products, Shifts} from 'common/types'
import {ShiftInterval} from 'components/intervals/horizontal/ShiftInterval'
import {ShiftViewer} from 'components/viewers/ShiftViewer'
import {groupBy, keyBy, keys} from 'lodash-es'
import moment from 'moment'
import {useMemo, useState} from 'react'
import {getConflictedIntervalGroups} from 'utils/getConflictedIntervalGroups'
import {IntervalInfo, getIntervals} from 'utils/getIntervals'
import {HorizontalIntervalCalendar} from '../basic/HorizontalIntervalCalendar'

type Props = {
  year: number
  month: number
  day: number
  shifts: Shifts.Basic.AugmentedEntity[]
  products: Products.Basic.StorableEntity[]
  locations: Locations.Basic.StorableEntity[]
  onChange: () => void
}

export const DayCalendar = ({year, month, day, shifts, products, locations: locations, onChange}: Props) => {
  const shiftMap = keyBy(shifts, 'id')
  const productMap = keyBy(products, 'id')
  const locationMap = keyBy(locations, 'id')
  const [focusedShiftId, setFocusedShiftId] = useState('')
  const [displayedShiftId, setDisplayedShiftId] = useState('')

  const startDateTime = moment.utc(moment({year, month, day})).format()
  const endDateTime = moment.utc(moment({year, month, day})).add(1, 'day').format()

  const groupedShifts = groupBy(
    shifts.map((shift) => ({...shift, entity: 'shift', entityId: shift.id})),
    'productId'
  )
  const regrouppedShifts = []

  for (const productId of keys(groupedShifts)) {
    const shiftGroup = groupedShifts[productId]
    for (const conflictedGroup of getConflictedIntervalGroups(shiftGroup)) {
      regrouppedShifts.push(conflictedGroup)
    }
  }

  const grouppedIntervals = regrouppedShifts.map((group) => getIntervals(startDateTime, endDateTime, group))

  const memoizedIntervalComponent = useMemo(() => {
    return function IntervalComponent(interval: IntervalInfo) {
      if (interval.entity === 'empty') {
        return <div className={cx('bg-transparent')} />
      }

      if (!interval.entityId) {
        throw new Error('Entity id is not defined')
      }

      const shift = shiftMap[interval.entityId]

      return (
        <ShiftInterval
          leftCut={interval.startOverflow}
          rightCut={interval.endOverflow}
          key={shift.id}
          shift={shift}
          product={productMap[shift.productId]}
          focus={shift.id === focusedShiftId}
          onClick={() => {
            setFocusedShiftId(shift.id)
            setDisplayedShiftId(shift.id)
          }}
        />
      )
    }
  }, [shifts, products, locations, focusedShiftId])

  return (
    <div>
      <HorizontalIntervalCalendar grouppedIntervals={grouppedIntervals} intervalComponent={memoizedIntervalComponent} />
      {displayedShiftId && (
        <ShiftViewer
          shift={shiftMap[displayedShiftId]}
          product={productMap[shiftMap[displayedShiftId].productId]}
          location={locationMap[productMap[shiftMap[displayedShiftId].productId].locationId]}
          onClose={() => setDisplayedShiftId('')}
          onChange={onChange}
        />
      )}
    </div>
  )
}
