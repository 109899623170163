import cx from 'classnames'
import {Guides} from 'common/types'
import {ReactComponent as AddCircle} from 'icons/AddCircle.svg'
import {ReactComponent as ArrowBack} from 'icons/ArrowBack.svg'
import {keyBy} from 'lodash-es'
import {Components} from 'ui'

type Props = {
  guides: Guides.Basic.StorableSortableEntity[]
  className?: string
  allowedGuideIds: string[]
  onAddAllowedGuide: (id: string) => void
  onAutoAssign: (ids: string[]) => void
  isLoading: boolean
}

export const AllowedGuidelist = ({guides, allowedGuideIds, className, onAddAllowedGuide, onAutoAssign, isLoading}: Props) => {
  const headers = [
    {key: 'control', value: ''},
    {key: 'name', value: 'Name'},
    {key: 'assignmentNumber', value: <>Qty of<br/>assignments</>},
    {key: 'guideStatus', value: <>Performance<br/>status</>},
  ]

  const guideMap = keyBy(guides, 'id')
  const allowedGuides = allowedGuideIds.map((id) => guideMap[id])

  const rows = isLoading
    ? []
    : allowedGuides.map((guide) => ({
        key: guide.id,
        columns: {
          name: (
            <span className="whitespace-nowrap">
              {guide.lastName} {guide.firstName.slice(0, 1)}.
            </span>
          ),
          assignmentNumber: guide.assignmentNumber,
          guideStatus: guide.status,
          control: <AddCircle className="w-6 h-6 cursor-pointer" onClick={() => onAddAllowedGuide(guide.id)} />,
        },
      }))

  return (
    <div className={cx('flex flex-col', className)}>
      <div className="py-3 px-3 rounded-t-lg font-semibold bg-gray-50 border flex justify-between items-center">
        Allowed guides ({rows.length})
        <Components.Button
          size="xs"
          color="green"
          className="flex gap-2 items-center"
          onClick={() => onAutoAssign(allowedGuideIds)}
          disabled={guides.length === 0}
        >
          <ArrowBack className="w-4 h-4" />
          Auto assign
        </Components.Button>
      </div>
      {isLoading && (
        <Components.Table
          headers={[{key: 'loading', value: 'Loading...'}]}
          rows={[]}
          headTrClassName="sticky top-0 bg-slate-500 text-white h-[56px]"
        />
      )}
      {!isLoading && (
        <Components.Table
          headers={headers}
          rows={rows}
          bodyTdClassName="!px-2"
          bodyThClassName="!px-2"
          headThClassName="!px-2"
          bodyTrClassName="!bg-gray-200 border-y-4 border-white"
          headTrClassName="sticky top-0 bg-slate-500 text-white"
        />
      )}
    </div>
  )
}
