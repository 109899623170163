import {userApi} from 'api'
import {Params, Responses} from 'common/types/users'
import {Utils} from 'ui'

export const userHooks = new Utils.CrudHooks<
  Params.Count,
  Params.List,
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update
>('user', 'User', userApi)
