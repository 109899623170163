import {DEFAULT_OPERATOR_GROUP_FILTERS} from 'components/dashboard/OperatorGroupFilters'
import {View} from 'components/dashboard/ViewSwitcher'
import {locationHooks} from 'hooks/locations'
import {productHooks} from 'hooks/products'
import {useState} from 'react'
import {DayView} from './views/DayView'
import {MonthView} from './views/MonthView'
import {WeekView} from './views/WeekView'

export const Scheduling = () => {
  const [view, setView] = useState<View>('month')
  const [groupFilters, setGroupFilters] = useState(DEFAULT_OPERATOR_GROUP_FILTERS)
  const productListQuery = productHooks.useListQuery({ordering: ['name']})
  const locationListQuery = locationHooks.useListQuery({ordering: ['name']})

  return (
    <>
      {view === 'month' && (
        <MonthView
          products={productListQuery.data}
          locations={locationListQuery.data}
          onChangeView={setView}
          groupFilters={groupFilters}
          onChangeGroupFilters={setGroupFilters}
        />
      )}
      {view === 'week' && (
        <WeekView
          products={productListQuery.data}
          locations={locationListQuery.data}
          onChangeView={setView}
          groupFilters={groupFilters}
          onChangeGroupFilters={setGroupFilters}
        />
      )}
      {view === 'day' && (
        <DayView
          products={productListQuery.data}
          locations={locationListQuery.data}
          onChangeView={setView}
          groupFilters={groupFilters}
          onChangeGroupFilters={setGroupFilters}
        />
      )}
    </>
  )
}
