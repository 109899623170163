import {assignmentApi} from 'api'
import {CrudApi} from 'api/assignments'
import {Params, Responses} from 'common/types/assignments'
import {useMutation} from 'react-query'
import {Providers, Utils} from 'ui'

class CrudHooks extends Utils.CrudHooks<
  Params.Count,
  Params.List,
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update,
  CrudApi
> {
  useBulkChangeMutation() {
    const add = Providers.ToasterProvider.useToasterMessageAdder()
    return useMutation<void, unknown, Params.BulkChange>((params: Params.BulkChange) => this.crudApi.bulkChange(params), {
      onSuccess: () => {
        add({
          message: `Changes applied`,
          type: 'success',
        })
      },
    })
  }

  useAutoAssignMutation() {
    const add = Providers.ToasterProvider.useToasterMessageAdder()
    return useMutation<void, unknown, Params.AutoAssign>((params: Params.AutoAssign) => this.crudApi.autoAssign(params), {
      onSuccess: () => {
        add({
          message: `Changes applied`,
          type: 'success',
        })
      },
    })
  }

  useSelfAssignMutation() {
    const add = Providers.ToasterProvider.useToasterMessageAdder()
    return useMutation<void, unknown, Params.SelfAssign>((params: Params.SelfAssign) => this.crudApi.selfAssign(params), {
      onSuccess: () => {
        add({
          message: `Changes applied`,
          type: 'success',
        })
      },
    })
  }
}

export const assignmentHooks = new CrudHooks('assignment', 'Assignment', assignmentApi)
