import {Assignments, Locations, Products, Shifts} from 'common/types'
import {ShiftAssignmentViewer} from 'components/viewers/ShiftAssignmentViewer'
import {keyBy} from 'lodash-es'
import {useMemo, useState} from 'react'
import {ShiftCard} from './ShiftCard'

export type ListItemComponentProps = {
  shiftId: string
}

type Props = {
  shifts: Shifts.Basic.AugmentedEntity[]
  shiftAssignments: Assignments.Basic.StorableEntity[]
  products: Products.Basic.StorableEntity[]
  locations: Locations.Basic.StorableEntity[]
  onChange: () => void
}

export const ShiftList = ({shifts, shiftAssignments, products, locations, onChange}: Props) => {
  const shiftAssignmentMap = keyBy(shiftAssignments, 'shiftId')
  const shiftMap = keyBy(shifts, 'id')
  const productMap = keyBy(products, 'id')
  const locationMap = keyBy(locations, 'id')

  const [focusedShiftId, setFocusedShiftId] = useState('')
  const [displayedShiftId, setDisplayedShiftId] = useState('')

  const ShiftCardComponent = useMemo(() => {
    return function AssignedShift({shiftId}: ListItemComponentProps) {
      const shiftAssignment = shiftAssignmentMap[shiftId]
      const shift = shiftMap[shiftId]
      const product = productMap[shift.productId]

      return (
        <ShiftCard
          onClick={() => {
            setFocusedShiftId(shift.id)
            setDisplayedShiftId(shift.id)
          }}
          shift={shift}
          shiftAssignment={shiftAssignment}
          product={product}
          focus={focusedShiftId === shift.id}
        />
      )
    }
  }, [shiftMap, productMap, shiftAssignmentMap])

  return (
    <div>
      <div className="flex flex-col gap-4">
        {shifts.map((shift) => {
          return <ShiftCardComponent key={shift.id} shiftId={shift.id} />
        })}
      </div>
      {displayedShiftId && (
        <ShiftAssignmentViewer
          shift={shiftMap[displayedShiftId]}
          shiftAssignment={shiftAssignmentMap[displayedShiftId]}
          product={productMap[shiftMap[displayedShiftId].productId]}
          location={locationMap[productMap[shiftMap[displayedShiftId].productId].locationId]}
          onClose={() => setDisplayedShiftId('')}
          onChange={onChange}
        />
      )}
    </div>
  )
}
