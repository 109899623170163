import moment from 'moment'
import {MonthFilter} from './MonthFilter'
import {YearFilter} from './YearFilter'

type MonthViewFiltersValue = {
  year: number
  month: number
}

type Props = {
  value: MonthViewFiltersValue
  onChange: (value: MonthViewFiltersValue) => void
}

export const getDefaultMonthViewFilters = (): MonthViewFiltersValue => {
  const now = moment()

  return {
    year: now.year(),
    month: now.month(),
  }
}

export const MonthViewFilters = ({value, onChange}: Props) => {
  return (
    <div className="flex gap-2 items-center">
      <YearFilter year={value.year} onChange={(year) => onChange({...value, year})} />
      <MonthFilter month={value.month} onChange={(month) => onChange({...value, month})} />
    </div>
  )
}
