import moment from 'moment'

export type Options = {
  showDate?: boolean
  showDay?: boolean
}

export const formatInterval = (startDateTime: string, endDateTime: string, options: Options = {}) => {
  const mStartDateTime = moment(startDateTime)
  const mEndDateTime = moment(endDateTime)

  if (mEndDateTime.diff(mStartDateTime, 'days') < 1 && !options.showDate) {
    return `${mStartDateTime.format('HH:mm')} - ${mEndDateTime.format('HH:mm')}`
  }

  const dayFormat = options.showDay ? 'ddd, MMM D' : 'MMM D'

  if (mEndDateTime.isSame(mStartDateTime, 'day') || options.showDate) {
    return `${mStartDateTime.format(dayFormat)} • ${mStartDateTime.format('HH:mm')} - ${mEndDateTime.format('HH:mm')}`
  }

  return `${mStartDateTime.format(`${dayFormat}, HH:mm`)} - ${mEndDateTime.format(`${dayFormat}, HH:mm`)}`
}
