import {Users} from 'common/types'
import {Params} from 'common/types/auth'
import {axiosInstance} from './getInstance'

export const iAm = async (): Promise<Users.Responses.IAm> => {
  const response = await axiosInstance.post<Users.Responses.IAm>('/users/iAm')
  return response.data
}

export const signIn = async (params: Params.SignIn): Promise<Users.Responses.SignIn> => {
  const response = await axiosInstance.post<Users.Responses.SignIn>('/users/signIn', params)
  return response.data
}

