import {Switcher} from 'components/Switcher'
import {WeekCalendar} from 'components/calendars/guide/WeekCalendar'
import {ShiftList} from 'components/lists/ShiftList'
import {assignmentHooks} from 'hooks/assignments'
import {locationHooks} from 'hooks/locations'
import {productHooks} from 'hooks/products'
import {shiftHooks} from 'hooks/shifts'
import {unavailabilityHooks} from 'hooks/unavailabilities'
import {map} from 'lodash-es'
import moment from 'moment'
import {UserProvider} from 'providers/UserProvider'
import {useState} from 'react'
import {Components} from 'ui'
import {formatInterval} from 'utils/formatInterval'

export const Available = () => {
  const user = UserProvider.useUser()
  const [view, setView] = useState<'calendar' | 'list'>('list')
  const [startDateTime] = useState(moment.utc(moment().startOf('day')).format())
  const endDateTime = moment.utc(startDateTime).add(1, 'week').format()

  const shiftListQuery = shiftHooks.useListQuery(
    {
      filters: [
        // {field: 'startDateTime', value: startDateTime, op: '>'},
        // {field: 'startDateTime', value: endDateTime, op: '<'},
        {field: 'status', value: 'published'},
        // {field: `isAvailable`, value: true},
        {field: 'isDeleted', value: false},
      ],
      // ordering: ['startDateTime'],
    },
    {
      keepPreviousData: true,
    }
  )

  const shiftAssigmentListQuery = assignmentHooks.useListQuery(
    {
      filters: [
        {field: 'guideId', value: user.id},
        // {field: 'fromDateTime', value: startDateTime},
        // {field: 'toDateTime', value: endDateTime},
      ],
    },
    {
      keepPreviousData: true,
    }
  )

  const unavailabilityListQuery = unavailabilityHooks.useListQuery(
    {
      filters: [
        // {field: 'fromDateTime', value: startDateTime},
        // {field: 'toDateTime', value: endDateTime},
        {field: 'guideId', value: user.id},
      ],
    },
    {
      keepPreviousData: true,
    }
  )

  const productListQuery = productHooks.useListQuery({
    ordering: ['name'],
  })

  const locationListQuery = locationHooks.useListQuery({
    ordering: ['name'],
  })

  if (!shiftListQuery.data || !shiftAssigmentListQuery.data || !unavailabilityListQuery.data || !productListQuery.data || !locationListQuery.data) {
    return null
  }

  const assignedShiftIds = map(shiftAssigmentListQuery.data, 'shiftId')

  const availableShifts = shiftListQuery.data.filter((shift) => {
    if (assignedShiftIds.includes(shift.id)) {
      return false
    }

    // for (const unavailability of unavailabilityListQuery.data) {
    //   if (shift.startDateTime >= unavailability.startDateTime && shift.startDateTime < unavailability.endDateTime) {
    //     return false
    //   }

    //   if (shift.endDateTime >= unavailability.startDateTime && shift.endDateTime < unavailability.endDateTime) {
    //     return false
    //   }
    // }

    return true
  })

  const onChange = () => {
    unavailabilityListQuery.refetch()
    shiftAssigmentListQuery.refetch()
    shiftListQuery.refetch()
  }

  return (
    <div className="flex flex-col p-4 gap-4">
      <div className="flex items-start justify-between">
        <div className="flex flex-col mt-1">
          <Components.Text weight="semibold">Available shifts</Components.Text>
          <Components.Text className="!text-[11px]">{formatInterval(startDateTime, endDateTime)}</Components.Text>
        </div>
        <Switcher
          leftLabel="List"
          rightLabel="Calendar"
          checked={view === 'calendar'}
          onChange={(event) => setView(event.target.checked ? 'calendar' : 'list')}
        />
      </div>

      {view === 'calendar' && (
        <WeekCalendar
          startOfWeek={startDateTime}
          shifts={availableShifts}
          assignments={[]}
          unavailabilities={unavailabilityListQuery.data}
          products={productListQuery.data}
          locations={locationListQuery.data}
          onChange={onChange}
        />
      )}
      {view === 'list' && (
        <ShiftList
          shifts={availableShifts}
          shiftAssignments={[]}
          products={productListQuery.data}
          locations={locationListQuery.data}
          onChange={onChange}
        />
      )}
    </div>
  )
}
