import {Components, Types} from 'ui'

type Props = {
  options: Types.SelectOptions
  productId: string
  onChange: (productId: string) => void
}

export const ProductFilter = ({options, productId, onChange}: Props) => {
  return (
    <div className="flex gap-2 items-center">
      <Components.Label>Product</Components.Label>
      <Components.Select
        options={options}
        value={productId}
        size="sm"
        onChange={(event) => onChange(event.target.value)}
        className="!w-56"
        notSelectedOption
      />
    </div>
  )
}
