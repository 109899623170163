export const PRONG_WIDTH = 10

type Edge = 'top' | 'bottom' | 'left' | 'right'
export type BorderStyle = 'solid' | 'dashed'

type Props = {
  size: number
  backgroundColor: string
  borderColor: string
  edge: Edge
  style: BorderStyle
}

const getSegmentQty = (size: number) => {
  const prongQty = Math.floor(size / 10) + 2
  return prongQty * 2
}

const coordinateGenerators = {
  left: (i: number) => (i % 2 ? `7,${5 * i}` : `2,${5 * i}`),
  right: (i: number) => (i % 2 ? `3,${5 * i}` : `8,${5 * i}`),
  top: (i: number) => (i % 2 ? `${5 * i},7` : `${5 * i},2`),
  bottom: (i: number) => (i % 2 ? `${5 * i},3` : `${5 * i},8`),
}

const getPolylineCoordinate = (i: number, edge: Edge) => {
  return coordinateGenerators[edge](i)
}

export const CuttedBorder = ({size, backgroundColor, borderColor, edge, style}: Props) => {
  const coordinates = []
  const segmentQty = getSegmentQty(size)

  for (let i = -2; i < segmentQty - 1; i++) {
    coordinates.push(getPolylineCoordinate(i, edge))
  }

  const points = coordinates.join(' ')

  return (
    <svg
      height={['top', 'bottom'].includes(edge) ? 10 : size}
      width={['left', 'right'].includes(edge) ? 10 : size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        points={points}
        style={{
          fill: backgroundColor,
          stroke: borderColor,
          strokeWidth: 2,
          strokeDasharray: style === 'dashed' ? '4 1' : undefined,
        }}
      />
    </svg>
  )
}
