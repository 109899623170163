import {Params, Responses} from 'common/types/assignments'
import {Utils} from 'ui'
import {axiosInstance} from './getInstance'

export class CrudApi extends Utils.CrudApi<
  Params.Count,
  Params.List,
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update
> {
  async bulkChange(params: Params.BulkChange): Promise<void> {
    await this.axiosInstance.post<void>(this.entityApiPath + '/bulkChange', params)
  }

  async autoAssign(params: Params.AutoAssign): Promise<void> {
    await this.axiosInstance.post<void>(this.entityApiPath + '/autoAssign', params)
  }

  async selfAssign(params: Params.SelfAssign): Promise<void> {
    await this.axiosInstance.post<void>(this.entityApiPath + '/selfAssign', params)
  }
}

export const crudApi = new CrudApi('/assignments', axiosInstance)
