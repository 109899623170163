import {locationHooks} from 'hooks/locations'
import {isEmpty} from 'lodash-es'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {Components, Fields} from 'ui'

export const Create = () => {
  const navigate = useNavigate()

  const createMutation = locationHooks.useCreateMutation()

  const onSubmit = (values: any) => {
    createMutation.mutate(values, {
      onSuccess(location) {
        navigate(`/locations/${location.id}/edit`)
      },
    })
  }

  return (
    <div className="flex flex-col gap-6">
      <Components.Header.H5>Add location</Components.Header.H5>

      <Form
        onSubmit={onSubmit}
        render={({handleSubmit, pristine, values}) => {
          const disableSave = pristine || isEmpty(values.name) || isEmpty(values.code) || isEmpty(values.timeZone)

          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="Name" name="name" expanded />
              <Fields.InputField label="Code" name="code" expanded />
              <Fields.InputField label="Time zone" name="timeZone" expanded />

              <Components.Button disabled={disableSave} className="ml-auto">
                Save
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
