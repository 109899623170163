import {shiftApi} from 'api'
import {Params, Responses} from 'common/types/shifts'
import {Utils} from 'ui'

export const shiftHooks = new Utils.CrudHooks<
  Params.Count,
  Params.List,
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update
>('shift', 'Shift', shiftApi)
