import cx from 'classnames'
import {Assignments} from 'common/types'
import {ASSIGNMENT_STATUSES} from 'consts'
import {Pill} from './Pill'

type Props = {
  assignment: Assignments.Basic.Entity | null | undefined
  showNothingIfNotDefined?: boolean
}

const getStatusName = (assignment: Assignments.Basic.Entity) => {
  if (assignment.status === 'accepted' && assignment.assignerId === assignment.guideId) {
    return 'Self assigned'
  }

  return ASSIGNMENT_STATUSES[assignment.status]
}

export const AssignmentStatus = ({assignment: assignment, showNothingIfNotDefined}: Props) => {
  if (!assignment) {
    return showNothingIfNotDefined ? null : <Pill className="border border-gray-500 !text-gray-500">Not assigned</Pill>
  }

  return (
    <Pill
      className={cx({
        'bg-green-500 !text-white': assignment.status === 'accepted',
        'border border-gray-500 !text-gray-500': assignment.status === 'pending',
        'bg-blue-500 !text-white': assignment.status === 'swapping',
      })}
    >
      {getStatusName(assignment)}
    </Pill>
  )
}
