import {MONTH_OPTIONS} from 'consts'
import {Components} from 'ui'

type Props = {
  month: number
  onChange: (month: number) => void
  showShortMonthNames?: boolean
}

export const MonthFilter = ({month, onChange, showShortMonthNames}: Props) => {
  const options = showShortMonthNames
    ? MONTH_OPTIONS.map((option) => {
        return {
          value: option.value,
          label: option.label.slice(0, 3),
        }
      })
    : MONTH_OPTIONS

  return (
    <div className="flex gap-2 items-center">
      <Components.Select
        options={options}
        value={month.toString()}
        size="sm"
        onChange={(event) => onChange(parseInt(event.target.value, 10))}
        className={showShortMonthNames ? '!w-20' : '!w-32'}
      />
    </div>
  )
}
